import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";
import { ReactSVG } from 'react-svg'
import { auth, firestore } from "../../services/firebase";
import GlobalState from '../../context/GlobalState';
import context from '../../context/context';
import "fontsource-clear-sans"
import smalllogo from '../../smalllogo.svg';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function styles(width) {
    let fullPageWidth = "80rem"
    let headerFontSize = "3rem"
    let h1FontSize = '3.7rem'
    let orFontSize = '2.2rem'
    let pFontSize = '1.3rem'
    let bottomLinks = '1.1rem'
    let mobile = false
    let sectionMarginBottom = '4.5rem'
    let topLinks = '1.1rem'
    let paddingRight = '3rem'
    let lineHeight = '1.5rem'
    let mobileButton = '20%'
    let h2FontSize = '1.8rem'
    if (width >= 1300) {
        fullPageWidth = "46rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        h2FontSize = '1.8rem'
        mobileButton = '20%'
        mobile = false
    } else if (width < 1300 && width >= 760) {
        fullPageWidth = "46rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        h2FontSize = '1.8rem'
        mobileButton = '40%'
        mobile = false
    } else if (width < 760 ) {
        fullPageWidth = "20rem"
        headerFontSize = "1.75rem"
        pFontSize = '1rem'
        h2FontSize = '1.4rem'
        h1FontSize = '2.5rem'
        orFontSize = '1.5rem'
        mobileButton = '100%'
        mobile = true
        sectionMarginBottom  = '2rem'
        topLinks = '0.9rem'
        paddingRight = '1rem'
        lineHeight = '1.1rem'
    }
    return { fullPageWidth, h2FontSize, headerFontSize, pFontSize, mobile, sectionMarginBottom, h1FontSize, orFontSize, topLinks, paddingRight, lineHeight, mobileButton }
}

function CookiesPolicy() {
    const history = useHistory();
    const { completed, setAllCompleted } = useContext(context);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column',  width: windowDimensions.width-(styles(windowDimensions.width).mobile ? 8 : 64), flex: 1, alignItems: 'center', height: '100vh', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", margin: 'auto' }}>
        <div style={{ 
            marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '6rem',
            display: 'flex', 
            flexDirection: 'row', 
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)',
            width: '100%'
        }}>
            <div style={{ display: 'flex', flex: 2, height: '4.5rem' }}>
                <Link to="/"> 
                    <ReactSVG beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('style', 'height: 4.5rem; width: 4rem; padding: 0.75rem 0 0.75rem 0.75rem')
                    }} src={smalllogo} style={{marginBottom: '0.75rem'}} />
                </Link>
            </div>
            <div style={{ display: 'flex', flex: 4, alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: '-1rem', display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, color: '#000' }} to="/questionlist">
                        Problem Index
                    </Link>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault();
                        const completedQuestions = Object.fromEntries(
                            Object.entries(completed).filter(([key, value]) => value === false) )
                        //console.log(Object.keys(completedQuestions).length*Math.random())
                        const randomIndex = Math.floor((Object.keys(completedQuestions).length)*Math.random())
                        
                        //history.push('/your-route');
                        history.push(`/q/${Object.keys(completedQuestions)[randomIndex]}`)
                    }}>
                        Problem Generator
                    </Link>
                    {auth().currentUser ? 
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault()
                        auth().signOut()
                    }}>{`Logout`}<br />{auth().currentUser.email}</Link>
                    : <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} to="/login">
                        {`Login or Sign Up`}
                    </Link>}
                </div>
            </div>
        </div>
        <div style={{ 
            marginBottom: '2rem', 
            paddingBottom: '0rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: styles(windowDimensions.width).fullPageWidth, 
            alignItems: 'center', 
            justifyContent: 'center',
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)'
        }}>
            <h1 style={{ fontSize: styles(windowDimensions.width).h1FontSize, fontWeight: 600, lineHeight: '4.5rem', marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '4.5rem', width: '100%', textAlign: 'center' }}>Cookies Policy</h1>
        </div>
        <div style={{ 
                marginBottom: styles(windowDimensions.width).sectionMarginBottom, 
                paddingBottom: '3rem', 
                display: 'flex', 
                flexDirection: 'column', 
                width: styles(windowDimensions.width).fullPageWidth, 
                alignItems: 'center', 
                justifyContent: 'center',
                borderBottomWidth: '0.225rem',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgb(236, 240, 241)'
            }}>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    In this Policy, the “Website” means the i-want-to-study-engineering.org website operated by the i-want-to-study-engineering.org project at the Cambridge University Engineering Department (the “Project” or “we”). The term “you” refers to the user or viewer of the Website. By using the Website, you consent to the data practices described in this policy. The policy explains what “cookie” information collection technology we use when you visit the Website.
                </p>
                <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>What are cookies?</h2>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    A cookie is a small text file that is sent to your browser and stored on the hard drive of your computer or internet-enabled device when you visit a website. Cookies can do a number of things such as remember your preferences and count the number of people looking at a website.
                </p>
                <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Examples of how cookies are used on the Website:</h2>
                <ul style={{ padding: 0, margin: 0, width: styles(windowDimensions.width).fullPageWidth, marginBottom: '1.5rem' }}>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>Strictly necessary cookies – These are cookies that are required for the operation of a website, essential to enable you to move around the site and use its features.</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>Analytical/performance cookies – These cookies collect information in an anonymous form about how visitors use a website. They recognise and count the number of visitors to see how visitors use the website.</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>Functionality cookies – These cookies are used to recognise the user when they return to the website. This enables personalising content and remembering user preferences.</p>
                    </li>
                </ul>
                <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>More information about the individual cookies we use and the purposes for which we use them and their expiry periods:</h2>
                <ul style={{ padding: 0, margin: 0, width: styles(windowDimensions.width).fullPageWidth, marginBottom: '1.5rem' }}>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        {styles(windowDimensions.width).mobile ? <p style={{ margin: 0, padding: 0 }}>JSESSIONID – Purpose: used to provide an id for users who are not currently logged in using the unique id for the session Data stored: information about the session; contains no personal information Expiry: when session has ended Further information: http://www.oracle.com/<br />technetwork/java/javaee<br />/servlet/index.html</p> 
                        : <p style={{ margin: 0, padding: 0 }}>JSESSIONID – Purpose: used to provide an id for users who are not currently logged in using the unique id for the session Data stored: information about the session; contains no personal information Expiry: when session has ended Further information: http://www.oracle.com/technetwork/java/javaee/servlet/index.html</p> }
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>attempt – Purpose: used to record the number of times a user has attempted any question Data stored: integer; contains no personal information Expiry: when session has ended Further information: Helps to provide a helpful message which suggests a user should log in to the system</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>cookiesDirective – Purpose: stores a cookie when a user has accepted our cookie policy Data stored: integer; contains no personal information Expiry: 1 year Further information: The cookies policy pop-up will not appear when accepted</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>cookiesDisclosureCount – Purpose: stores how many times a user has been shown the cookie policy pop-up Data stored: integer; contains no personal information Expiry: 1 day</p>
                    </li>
                </ul>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    You may block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you set your browser settings to block all cookies including essential cookies you may not be able to access all or parts of our Website.
                </p>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    To find out more about cookies, including on how to see which cookies have been set and how to manage and delete them:
                    <a href="http://www.allaboutcookies.org"
                    style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', paddingLeft: 5 }}>AllAboutCookies.org</a>; 
                    <a href="http://www.aboutcookies.org"
                    style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', paddingLeft: 5 }}>AboutCookies.org</a>.
                </p>
                <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Links to other websites</h2>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    The Website may contain links to and from third party sites. This Cookie Policy does not cover the use of your personal data or cookies on any third party website. If you follow a link to any of those websites, please note that those websites have their own privacy and cookies policies and that we do not accept any responsibility or liability for those policies. Please check those policies before you submit any personal data to or use these websites.
                </p>
                <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Third party cookies</h2>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    The Website may have pages with embedded content from third parties such as social media sites, e.g. Facebook, Twitter, Flickr or YouTube. Cookies generated by those websites are subject to their own privacy policies and are outside the control of the Project, the Cambridge University Engineering Department and the University of Cambridge. Please check these policies before you submit any personal data or use these websites.
                </p>
                <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Access to personal information</h2>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    For the purpose of the Data Protection Act 1998, the "Data Controller" for the processing of data collected by this Website is the University of Cambridge, and the point of subject access requests is the University Data Protection Officer (The Old Schools, Trinity Lane,Cambridge CB2 1TN, tel. 01223 332320, fax 01223 332332, E-mail data.protection@admin.cam.ac.uk.
                </p>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    Last updated August 2022.
                </p>
        </div>
        <div style={{ 
            marginBottom: '4.5rem', 
            paddingBottom: '12rem', 
            display: 'flex', 
            flexDirection: 'row', 
            width: styles(windowDimensions.width).fullPageWidth,
            alignItems: 'center', 
            justifyContent: 'center',
        }}> 
            <span style={{ marginTop: '1.5rem' }}>
                <Link to="/terms_of_use" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5 }}>Terms of use</Link> · 
                <Link to="/privacy_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Privacy Policy</Link> · 
                <Link to="/cookies_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Cookies Policy</Link> · 
                <Link to="/system_requirements" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>System Requirements</Link> · 
                {/*<Link to="/faq" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>FAQ</Link> · */}
                <Link to="/credits" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingLeft: 5 }}>Credits</Link>
            </span>
        </div>
    </div>
  );
}

export default CookiesPolicy;