import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";
import { ReactSVG } from 'react-svg'
import { auth, firestore } from "../../services/firebase";
import GlobalState from '../../context/GlobalState';
import context from '../../context/context';
import "fontsource-clear-sans"
import smalllogo from '../../smalllogo.svg';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function styles(width) {
    let fullPageWidth = "80rem"
    let headerFontSize = "3rem"
    let h1FontSize = '3.7rem'
    let orFontSize = '2.2rem'
    let pFontSize = '1.3rem'
    let bottomLinks = '1.1rem'
    let mobile = false
    let sectionMarginBottom = '4.5rem'
    let topLinks = '1.1rem'
    let paddingRight = '3rem'
    let lineHeight = '1.5rem'
    let mobileButton = '20%'
    let h2FontSize = '1.8rem'
    if (width >= 1300) {
        fullPageWidth = "46rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        h2FontSize = '1.8rem'
        mobileButton = '20%'
        mobile = false
    } else if (width < 1300 && width >= 760) {
        fullPageWidth = "46rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        h2FontSize = '1.8rem'
        mobileButton = '40%'
        mobile = false
    } else if (width < 760 ) {
        fullPageWidth = "20rem"
        headerFontSize = "1.75rem"
        pFontSize = '1rem'
        h2FontSize = '1.4rem'
        h1FontSize = '2.5rem'
        orFontSize = '1.5rem'
        mobileButton = '100%'
        mobile = true
        sectionMarginBottom  = '2rem'
        topLinks = '0.9rem'
        paddingRight = '1rem'
        lineHeight = '1.1rem'
    }
    return { fullPageWidth, h2FontSize, headerFontSize, pFontSize, mobile, sectionMarginBottom, h1FontSize, orFontSize, topLinks, paddingRight, lineHeight, mobileButton }
}

function PrivacyPolicy() {
    const history = useHistory();
    const { completed, setAllCompleted } = useContext(context);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column',  width: windowDimensions.width-(styles(windowDimensions.width).mobile ? 8 : 64), flex: 1, alignItems: 'center', height: '100vh', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", margin: 'auto' }}>
        <div style={{ 
            marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '6rem',
            display: 'flex', 
            flexDirection: 'row', 
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)',
            width: '100%'
        }}>
            <div style={{ display: 'flex', flex: 2, height: '4.5rem' }}>
                <Link to="/"> 
                    <ReactSVG beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('style', 'height: 4.5rem; width: 4rem; padding: 0.75rem 0 0.75rem 0.75rem')
                    }} src={smalllogo} style={{marginBottom: '0.75rem'}} />
                </Link>
            </div>
            <div style={{ display: 'flex', flex: 4, alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: '-1rem', display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, color: '#000' }} to="/questionlist">
                        Problem Index
                    </Link>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault();
                        const completedQuestions = Object.fromEntries(
                            Object.entries(completed).filter(([key, value]) => value === false) )
                        //console.log(Object.keys(completedQuestions).length*Math.random())
                        const randomIndex = Math.floor((Object.keys(completedQuestions).length)*Math.random())
                        
                        //history.push('/your-route');
                        history.push(`/q/${Object.keys(completedQuestions)[randomIndex]}`)
                    }}>
                        Problem Generator
                    </Link>
                    {auth().currentUser ? 
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault()
                        auth().signOut()
                    }}>{`Logout`}<br />{auth().currentUser.email}</Link>
                    : <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} to="/login">
                        {`Login or Sign Up`}
                    </Link>}
                </div>
            </div>
        </div>
        <div style={{ 
            marginBottom: '2rem', 
            paddingBottom: '0rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: styles(windowDimensions.width).fullPageWidth, 
            alignItems: 'center', 
            justifyContent: 'center',
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)'
        }}>
            <h1 style={{ fontSize: styles(windowDimensions.width).h1FontSize, fontWeight: 600, lineHeight: '4.5rem', marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '4.5rem', width: '100%', textAlign: 'center' }}>Privacy Policy</h1>
        </div>
        <div style={{ 
                marginBottom: styles(windowDimensions.width).sectionMarginBottom, 
                paddingBottom: '3rem', 
                display: 'flex', 
                flexDirection: 'column', 
                width: styles(windowDimensions.width).fullPageWidth, 
                alignItems: 'center', 
                justifyContent: 'center',
                borderBottomWidth: '0.225rem',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgb(236, 240, 241)'
            }}>
            <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>General</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                In this Policy the “Website ” means the i-want-to-study-engineering.org website operated by the i-want-to-study-engineering.org project at the Cambridge University Engineering Department (the “Project ” or “we ”). The term “you ” refers to the user or viewer of the Website. By using the Website, you consent to the data practices described in this policy. This Policy (together with the Website 
                    <Link to="/terms_of_use" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: styles(windowDimensions.width).pFontSize, paddingRight: 5, paddingLeft: 5 }}>Terms of Use</Link> (and any other documents referred to in it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Any changes to this Privacy Policy will be posted on this page.
            </p>
            <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Data collected</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                We may collect and process the following data about you:
            </p>
            <ul style={{ padding: 0, margin: 0, width: styles(windowDimensions.width).fullPageWidth, marginBottom: '1.5rem' }}>
                <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                    <p style={{ margin: 0, padding: 0 }}>Information that you provide by filling in forms on the Website. This includes information provided at the time of registering or login, submitting material or requesting further services. We may also ask you for information when you report a problem with our Website.</p>
                </li>
                <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                    <p style={{ margin: 0, padding: 0 }}>Information that you provide when participating in any services offered by the Website.</p>
                </li>
                <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                    <p style={{ margin: 0, padding: 0 }}>If you communicate with us by phone, email or otherwise, we may keep a record of that correspondence.</p>
                </li>
                <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                    <p style={{ margin: 0, padding: 0 }}>Data captured from the games you play on the Website or download from it and play on your computer. This includes device identifiers and location data as well as game events such as which levels have been completed, how long they took and how long you play the games for.</p>
                </li>
            </ul>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                We may also collect the following information about your access to the Website, where available – your IP address, operating system and browser type – for the purposes of system administration, bug tracking, production of usage statistics and to trace misuse. Statistical data about our users does not identify any individual.
            </p>
            <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Cookies</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                This Website uses cookies that are small pieces of information that are issued to your computer or other internet-enabled device when you visit the Website. Cookies are stored by your browser and hard drive and are used to distinguish you from other users of our Website.
            </p>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                You are not obligated to accept cookies and you may change your browser settings to prevent it from accepting cookies. If you do disable cookies, certain services on this Website may not be available and may impair full use of its features. There are many sources of information about cookies and and disabling them, including: 
                <a href="http://www.allaboutcookies.org"
                style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', paddingLeft: 5 }}>AllAboutCookies.org</a> and 
                <a href="http://www.aboutcookies.org"
                style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', paddingLeft: 5 }}>AboutCookies.org</a>. For detailed information on the cookies we use and the purposes for which we use them, see our 
                <Link to="/cookies_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: styles(windowDimensions.width).pFontSize, paddingRight: 5, paddingLeft: 5 }}>Cookies Policy</Link>.
            </p>
            <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Security measures and potential international transfer of personal information</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                We follow appropriate security procedures in the storage and disclosure of personal data so as to prevent unauthorised access by third parties. We ensure that those parties to whom we do transfer personal data comply with the same.
            </p>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                The data we collect from you may be transferred and stored in a country or countries outside the European Economic Area (EEA). It may also be processed by our staff operating outside the EEA or by one of our contractors or others we appoint to assist us in hosting or operating certain features of this Website. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.
            </p>
            <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Disclosure and uses of personal information</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                We use information held about you in the following ways:
            </p>
            <ul style={{ padding: 0, margin: 0, width: styles(windowDimensions.width).fullPageWidth, marginBottom: '1.5rem' }}>
                <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                    <p style={{ margin: 0, padding: 0 }}>To ensure that content from our Website is presented in the most effective manner for you and for your computer or device by gathering aggregate information about our users and using it to analyse the effectiveness and popularity of this Website</p>
                </li>
                <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                    <p style={{ margin: 0, padding: 0 }}>To provide you with information that you request from us or which we feel may interest you, and to consult you about the service we provide</p>
                </li>
                <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                    <p style={{ margin: 0, padding: 0 }}>To allow you to participate in interactive features of our service, when you choose to do so</p>
                </li>
                <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                    <p style={{ margin: 0, padding: 0 }}>To notify you about changes to our service.</p>
                </li>
                <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                    <p style={{ margin: 0, padding: 0 }}>To improve future iterations of the games and also assess their educational impact. It may also be published in an anonymised and aggregated form as part of scientific papers.</p>
                </li>
            </ul>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                We may disclose your personal information to third parties in the following circumstances:
            </p>
            <ul style={{ padding: 0, margin: 0, width: styles(windowDimensions.width).fullPageWidth, marginBottom: '1.5rem' }}>
                <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                    <p style={{ margin: 0, padding: 0 }}>If we have obtained your consent to do so</p>
                </li>
                <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                    <p style={{ margin: 0, padding: 0 }}>When we are required or authorised by law, court order, regulatory or governmental authority to disclose your personal data</p>
                </li>
                <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                    <p style={{ margin: 0, padding: 0 }}>To enforce or apply our Website Terms of Use and other agreements, including but not limited to in relation to any content posted or uploaded to the Website in breach of our Terms of Use</p>
                </li>
                <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                    <p style={{ margin: 0, padding: 0 }}>If required to protect the rights, property or safety of the Project, the University of Cambridge, their members or others.</p>
                </li>
            </ul>
            <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Links to other websites</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                The Website may contain links to and from third party sites. This Privacy Policy does not cover the use of your personal data on any third party website. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.
            </p>
            <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Third party cookies</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                The Website may have pages with embedded content from third parties such as social media sites, e.g. Facebook, Twitter, Flickr or YouTube. Cookies generated by those sites are subject to their own privacy policies and are outside the control of the Project, the Cambridge University Engineering Department and the University of Cambridge. Please check these policies before you submit any personal data to or use these websites.
            </p>
            <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Deleting your data and account</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                To have your account and data deleted from the site, please email rih28@cam.ac.uk with the subject 'IWTSE account deletion'. 
            </p>
            <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Access to personal information</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                For the purpose of the Data Protection Act 1998, the ‘Data Controller’ for the processing of data collected by this Website is the University of Cambridge, and the point of subject access requests is the University Data Protection Officer (The Old Schools, Trinity Lane, Cambridge CB2 1TN, tel. 01223 332320, fax 01223 332332, E-mail data.protection@admin.cam.ac.uk .
            </p>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                Last updated August 2022.
            </p>
        </div>
        {false && <div style={{ 
            marginTop: '2rem', 
            marginBottom: '4.5rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: styles(windowDimensions.width).fullPageWidth, 
            alignItems: 'center', 
            justifyContent: 'left',
        }}> 
            <div style={{ justifyContent: 'flex-start' }}>
                <h1 style={{ fontSize: '3.7rem', fontWeight: 600, lineHeight: 1.2, marginBottom: '1.5rem', textAlign: 'center' }}>Privacy Policy</h1>
                <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>General</h2>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    In this Policy the “Website ” means the i-want-to-study-engineering.org website operated by the i-want-to-study-engineering.org project at the Cambridge University Engineering Department (the “Project ” or “we ”). The term “you ” refers to the user or viewer of the Website. By using the Website, you consent to the data practices described in this policy. This Policy (together with the Website 
                        <Link to="/terms_of_use" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: styles(windowDimensions.width).pFontSize, paddingRight: 5, paddingLeft: 5 }}>Terms of Use</Link> (and any other documents referred to in it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Any changes to this Privacy Policy will be posted on this page.
                </p>
                <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Data collected</h2>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    We may collect and process the following data about you:
                </p>
                <ul style={{ padding: 0, margin: 0, width: styles(windowDimensions.width).fullPageWidth, marginBottom: '1.5rem' }}>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>Information that you provide by filling in forms on the Website. This includes information provided at the time of registering or login, submitting material or requesting further services. We may also ask you for information when you report a problem with our Website.</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>Information that you provide when participating in any services offered by the Website.</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>If you communicate with us by phone, email or otherwise, we may keep a record of that correspondence.</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>Data captured from the games you play on the Website or download from it and play on your computer. This includes device identifiers and location data as well as game events such as which levels have been completed, how long they took and how long you play the games for.</p>
                    </li>
                </ul>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    We may also collect the following information about your access to the Website, where available – your IP address, operating system and browser type – for the purposes of system administration, bug tracking, production of usage statistics and to trace misuse. Statistical data about our users does not identify any individual.
                </p>
                <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Cookies</h2>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    This Website uses cookies that are small pieces of information that are issued to your computer or other internet-enabled device when you visit the Website. Cookies are stored by your browser and hard drive and are used to distinguish you from other users of our Website.
                </p>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    You are not obligated to accept cookies and you may change your browser settings to prevent it from accepting cookies. If you do disable cookies, certain services on this Website may not be available and may impair full use of its features. There are many sources of information about cookies and and disabling them, including: 
                    <a href="http://www.allaboutcookies.org"
                    style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', paddingLeft: 5 }}>AllAboutCookies.org</a> and 
                    <a href="http://www.aboutcookies.org"
                    style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', paddingLeft: 5 }}>AboutCookies.org</a>. For detailed information on the cookies we use and the purposes for which we use them, see our 
                    <Link to="/cookies_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: styles(windowDimensions.width).pFontSize, paddingRight: 5, paddingLeft: 5 }}>Cookies Policy</Link>.
                </p>
                <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Security measures and potential international transfer of personal information</h2>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    We follow appropriate security procedures in the storage and disclosure of personal data so as to prevent unauthorised access by third parties. We ensure that those parties to whom we do transfer personal data comply with the same.
                </p>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    The data we collect from you may be transferred and stored in a country or countries outside the European Economic Area (EEA). It may also be processed by our staff operating outside the EEA or by one of our contractors or others we appoint to assist us in hosting or operating certain features of this Website. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.
                </p>
                <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Disclosure and uses of personal information</h2>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    We use information held about you in the following ways:
                </p>
                <ul style={{ padding: 0, margin: 0, width: styles(windowDimensions.width).fullPageWidth, marginBottom: '1.5rem' }}>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>To ensure that content from our Website is presented in the most effective manner for you and for your computer or device by gathering aggregate information about our users and using it to analyse the effectiveness and popularity of this Website</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>To provide you with information that you request from us or which we feel may interest you, and to consult you about the service we provide</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>To allow you to participate in interactive features of our service, when you choose to do so</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>To notify you about changes to our service.</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>To improve future iterations of the games and also assess their educational impact. It may also be published in an anonymised and aggregated form as part of scientific papers.</p>
                    </li>
                </ul>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    We may disclose your personal information to third parties in the following circumstances:
                </p>
                <ul style={{ padding: 0, margin: 0, width: styles(windowDimensions.width).fullPageWidth, marginBottom: '1.5rem' }}>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>If we have obtained your consent to do so</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>When we are required or authorised by law, court order, regulatory or governmental authority to disclose your personal data</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>To enforce or apply our Website Terms of Use and other agreements, including but not limited to in relation to any content posted or uploaded to the Website in breach of our Terms of Use</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                        <p style={{ margin: 0, padding: 0 }}>If required to protect the rights, property or safety of the Project, the University of Cambridge, their members or others.</p>
                    </li>
                </ul>
                <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Links to other websites</h2>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    The Website may contain links to and from third party sites. This Privacy Policy does not cover the use of your personal data on any third party website. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.
                </p>
                <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Third party cookies</h2>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    The Website may have pages with embedded content from third parties such as social media sites, e.g. Facebook, Twitter, Flickr or YouTube. Cookies generated by those sites are subject to their own privacy policies and are outside the control of the Project, the Cambridge University Engineering Department and the University of Cambridge. Please check these policies before you submit any personal data to or use these websites.
                </p>
                <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Deleting your data and account</h2>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    To have your account and data deleted from the site, please email rih28@cam.ac.uk with the subject 'IWTSE account deletion'. 
                </p>
                <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Access to personal information</h2>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    For the purpose of the Data Protection Act 1998, the ‘Data Controller’ for the processing of data collected by this Website is the University of Cambridge, and the point of subject access requests is the University Data Protection Officer (The Old Schools, Trinity Lane, Cambridge CB2 1TN, tel. 01223 332320, fax 01223 332332, E-mail data.protection@admin.cam.ac.uk .
                </p>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    Last updated August 2022.
                </p>
            </div>
        </div>}
        <div style={{ 
            marginBottom: '4.5rem', 
            paddingBottom: '12rem', 
            display: 'flex', 
            flexDirection: 'row', 
            width: styles(windowDimensions.width).fullPageWidth,
            alignItems: 'center', 
            justifyContent: 'center',
        }}> 
            <span style={{ marginTop: '1.5rem' }}>
                <Link to="/terms_of_use" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5 }}>Terms of use</Link> · 
                <Link to="/privacy_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Privacy Policy</Link> · 
                <Link to="/cookies_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Cookies Policy</Link> · 
                <Link to="/system_requirements" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>System Requirements</Link> · 
                {/*<Link to="/faq" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>FAQ</Link> · */}
                <Link to="/credits" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingLeft: 5 }}>Credits</Link>
            </span>
        </div>
    </div>
  );
}

export default PrivacyPolicy;