import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
//require('typeface-clear-sans')
import "fontsource-clear-sans"
import logo from '../../logo.svg';
import logomobile from '../../logomobile.svg';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";
import { ReactSVG } from 'react-svg'
import { auth, firestore } from "../../services/firebase";
import GlobalState from '../../context/GlobalState';
import context from '../../context/context';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function styles(width) {
    let fullPageWidth = "46rem"
    let headerFontSize = "3rem"
    let pFontSize = '1.3rem'
    let bottomLinks = '1.1rem'
    let mobile = false
    let sectionMarginBottom = '4.5rem'
    if (width >= 1300) {
        fullPageWidth = "46rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        mobile = false
    } else if (width < 1300 && width >= 760) {
        fullPageWidth = "44rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        mobile = false
    } else if (width < 760 ) {
        fullPageWidth = "20rem"
        headerFontSize = "1.75rem"
        pFontSize = '1rem'
        mobile = true
        sectionMarginBottom  = '2rem'
    }
    return { fullPageWidth, headerFontSize, pFontSize, mobile, sectionMarginBottom }
}



function Home({ user }) {
    const history = useHistory();
    const [localUser, setLocalUser] = useState(null) 
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const { aqcompleted, completed, setAllCompleted, setAllAQCompleted, aqcompletedorder } = useContext(context);
    
    /*useEffect(() => {
        let user = auth().currentUser
        setLocalUser(user)
    }, [localUser]);*/

    //useEffect(() => {
        //console.log("Here 3", completed.balances)
    //}, [completed])

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

  //console.log("height and width", windowDimensions.width)
  return (
    <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        flex: 1, 
        alignItems: 'center', 
        height: '100vh', 
        fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", 
    }}>
        <div style={{ 
            marginTop: '4.5rem', 
            marginBottom: styles(windowDimensions.width).sectionMarginBottom, 
            paddingBottom: '3rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: styles(windowDimensions.width).fullPageWidth, //'46rem', 
            alignItems: 'center', 
            justifyContent: 'center',
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)'
        }}>
            {styles(windowDimensions.width).mobile ? <ReactSVG src={logomobile} style={{marginBottom: '0.75rem'}} /> : <ReactSVG src={logo} style={{marginBottom: '0.75rem'}} />}
            <h1 style={{ fontSize: styles(windowDimensions.width).headerFontSize, fontWeight: 600, lineHeight: 1.2, marginBottom: '0.75rem' }}>iWantToStudyEngineering</h1>
            <p style={{ textAlign: 'center', fontSize: styles(windowDimensions.width).pFontSize, marginRight: 'auto', marginLeft: 'auto', maxWidth: '36rem' }}>A website to help you compete for engineering places at top universities.</p>
        </div>
        <div style={{ 
            marginBottom: styles(windowDimensions.width).sectionMarginBottom, 
            paddingBottom: '3rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: styles(windowDimensions.width).fullPageWidth, 
            alignItems: 'center', 
            justifyContent: 'center',
        }}>
            <h2 style={{ fontSize: '2.2rem', fontWeight: 600, lineHeight: '2.625rem', marginBottom: '1.5rem' }}>Getting started</h2>
            {styles(windowDimensions.width).mobile ? 
                <div style={{ display: 'flex', flexDirection: 'column', width: "22rem" }}>
                    <div>
                        <p style={{ 
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            fontVariant: 'small-caps',
                            letterSpacong: '0.08rem',
                            borderBottom: 0,
                            color: '#e74c3c',
                            lineHeight: '1.2rem',
                            outline: 'none',
                            fontSize: '1.1rem',
                            marginBottom: '1.5rem',
                            marginLeft: '1em',
                            marginRight: '1em',
                        }}>
                            MATHEMATICS AND PHYSICS PROBLEMS AT THE LEVEL OF UNIVERSITY ENGINEERING ADMISSIONS INTERVIEWS
                        </p>
                    </div>
                    <div>
                        <Link to="/questionlist">
                            <button style={{ 
                                textTransform: 'uppercase', 
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                boxShadow: "0 0.2rem #d62c1a",
                                backgroundColor: "#e74c3c",
                                textShadow: "0 1px #a82315",
                                color: "white",
                                position: 'relative',
                                padding: "1.2rem 1.2rem",
                                marginBottom: "1.0rem",
                                lineHeight: "1.5rem",
                                border: 0,
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                fontFamily: "Clear Sans",
                                outline: "none",
                                textAlign: "center",
                                width: "100%"
                            }}>PROBLEM<br/>INDEX</button>
                        </Link>
                        <p style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.5rem', 
                            margin: 0, 
                            marginBottom: '1.5rem',
                            padding: 0,
                            border: 0,
                            verticalAlign: 'baseline'
                        }}>Choose from over 200 problems.</p>
                    </div>
                    <div>
                        <Link>
                            <button onClick={
                                (e) => {
                                    e.preventDefault();
                                    const completedQuestions = Object.fromEntries(
                                        Object.entries(completed).filter(([key, value]) => value === false) )
                                    //console.log(Object.keys(completedQuestions).length*Math.random())
                                    const randomIndex = Math.floor((Object.keys(completedQuestions).length)*Math.random())
                                    
                                    //history.push('/your-route');
                                    history.push(`/q/${Object.keys(completedQuestions)[randomIndex]}`)
                                }
                            } style={{ 
                                textTransform: 'uppercase', 
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                boxShadow: "0 0.2rem #d62c1a",
                                backgroundColor: "#e74c3c",
                                textShadow: "0 1px #a82315",
                                color: "white",
                                position: 'relative',
                                padding: "1.2rem 1.2rem",
                                marginBottom: "1.0rem",
                                lineHeight: "1.5rem",
                                border: 0,
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                fontFamily: "Clear Sans",
                                outline: "none",
                                textAlign: "center",
                                width: "100%"
                            }}>PROBLEM<br/>GENERATOR</button>
                        </Link>
                        <p style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.5rem', 
                            margin: 0, 
                            marginBottom: '1.5rem',
                            padding: 0,
                            border: 0,
                            verticalAlign: 'baseline'
                        }}>Let the system pick a problem for you based on those you’ve already completed.</p>
                    </div>
                    {<div>
                        <p style={{ 
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            fontVariant: 'small-caps',
                            letterSpacong: '0.08rem',
                            borderBottom: 0,
                            color: '#2980b9',
                            lineHeight: '1.2rem',
                            outline: 'none',
                            fontSize: '1.1rem',
                            marginBottom: '1.5rem',
                            marginLeft: '1em',
                            marginRight: '1em',
                        }}>A-LEVEL MATHEMATICS AND PHYSICS PROBLEMS ADAPTED FROM OCR EXAM PAPERS</p>
                    </div>}
                    {<div>
                        <Link to="/alevelquestionlist" onClick={(e) => {
                                //e.preventDefault()
                            }}>
                            <button 
                            style={{ 
                                //opacity: 0.5,
                                textTransform: 'uppercase', 
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                boxShadow: "0 0.2rem #20638f",
                                backgroundColor: "#2980b9",
                                textShadow: "0 1px #164666",
                                color: "white",
                                position: 'relative',
                                padding: "1.2rem 1.2rem",
                                marginBottom: "1.0rem",
                                lineHeight: "1.5rem",
                                border: 0,
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                fontFamily: "Clear Sans",
                                outline: "none",
                                textAlign: "center",
                                width: "100%"
                            }}>A-LEVEL QUESTION<br/>INDEX</button>
                        </Link>
                        <p style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.5rem', 
                            margin: 0, 
                            marginBottom: '1.5rem',
                            padding: 0,
                            border: 0,
                            verticalAlign: 'baseline'
                        }}>Choose from maths and physics a-level questions.</p>
                    </div>}
                    {<div>
                        <Link>
                            <button 
                            onClick={(e) => {
                                e.preventDefault();
                                
                                
                                //history.push('/your-route');
                                history.push(`/aq/${aqcompletedorder[0]}`)
                            }}
                            style={{ 
                                //opacity: 0.5,
                                textTransform: 'uppercase', 
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                boxShadow: "0 0.2rem #20638f",
                                backgroundColor: "#2980b9",
                                textShadow: "0 1px #164666",
                                color: "white",
                                position: 'relative',
                                padding: "1.2rem 1.2rem",
                                marginBottom: "1.0rem",
                                lineHeight: "1.5rem",
                                border: 0,
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                fontFamily: "Clear Sans",
                                outline: "none",
                                textAlign: "center",
                                width: "100%"
                            }}>NEXT A-LEVEL<br/>QUESTION</button>
                        </Link>
                        <p style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.5rem', 
                            margin: 0, 
                            marginBottom: '1.5rem',
                            padding: 0,
                            border: 0,
                            verticalAlign: 'baseline'
                        }}>Let the system choose your next a-level question.</p>
                    </div>}
                    <div>
                        {user ?
                            <button 
                              onClick={() =>
                                auth().signOut()
                              }
                              style={{ 
                                textTransform: 'uppercase', 
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                boxShadow: "0 0.2rem #1e8449",
                                backgroundColor: "#27ae60",
                                textShadow: "0 1px #145b32",
                                color: "white",
                                position: 'relative',
                                padding: "1.2rem 1.2rem",
                                marginBottom: "1.0rem",
                                lineHeight: "1.5rem",
                                border: 0,
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                fontFamily: "Clear Sans",
                                outline: "none",
                                textAlign: "center",
                                width: "100%"
                            }}>LOG OUT<br/>{user.email}</button>
                        : <Link to="/login">
                            <button style={{ 
                                textTransform: 'uppercase', 
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                boxShadow: "0 0.2rem #1e8449",
                                backgroundColor: "#27ae60",
                                textShadow: "0 1px #145b32",
                                color: "white",
                                position: 'relative',
                                padding: "1.2rem 1.2rem",
                                marginBottom: "1.0rem",
                                lineHeight: "1.5rem",
                                border: 0,
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                fontFamily: "Clear Sans",
                                outline: "none",
                                textAlign: "center",
                                width: "100%"
                            }}>LOGIN OR<br/>SIGN UP</button>
                        </Link>}
                        <p style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.5rem', 
                            margin: 0, 
                            marginBottom: '1.5rem',
                            padding: 0,
                            border: 0,
                            verticalAlign: 'baseline'
                        }}>Log in securely to track your progress.</p>
                    </div>
                    <div>
                        <p style={{ 
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            fontVariant: 'small-caps',
                            letterSpacong: '0.08rem',
                            borderBottom: 0,
                            color: '#df9900',
                            lineHeight: '1.2rem',
                            outline: 'none',
                            fontSize: '1.1rem',
                            marginBottom: '1.5rem',
                            marginLeft: '1em',
                            marginRight: '1em',
                        }}>GAMES DESIGNED TO TEACH IDEAS THAT ARE IMPORTANT IN MATHEMATICS AND PHYSICS</p>
                    </div>
                    <div>
                        <a href="https://wiredthegame.com/">
                            <button
                              
                              style={{ 
                                textTransform: 'uppercase', 
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                boxShadow: "0 0.2rem #da7900",
                                backgroundColor: "#df9900",
                                textShadow: "0 1px #a97500",
                                color: "white",
                                position: 'relative',
                                padding: "1.2rem 1.2rem",
                                marginBottom: "1.0rem",
                                lineHeight: "1.5rem",
                                border: 0,
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                fontFamily: "Clear Sans",
                                outline: "none",
                                textAlign: "center",
                                width: "100%"
                            }}>WIRED</button>
                        </a>
                        <p style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.5rem', 
                            margin: 0, 
                            marginBottom: '1.5rem',
                            padding: 0,
                            border: 0,
                            verticalAlign: 'baseline'
                        }}>Wired is first and foremost a fun game, but if you play it, you will also learn about the principles of electrical circuits.</p>
                    </div>
                    <div>
                        
                        <a href={"https://maths.builders/"}>
                            <button 
                            
                            style={{ 
                                textTransform: 'uppercase', 
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                boxShadow: "0 0.2rem #da7900",
                                backgroundColor: "#df9900",
                                textShadow: "0 1px #a97500",
                                color: "white",
                                position: 'relative',
                                padding: "1.2rem 1.2rem",
                                marginBottom: "1.0rem",
                                lineHeight: "1.5rem",
                                border: 0,
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                fontFamily: "Clear Sans",
                                outline: "none",
                                textAlign: "center",
                                width: "100%"
                            }}>MATHS<br/>BUILDERS</button>
                        </a>
                        <p style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.5rem', 
                            margin: 0, 
                            marginBottom: '1.5rem',
                            padding: 0,
                            border: 0,
                            verticalAlign: 'baseline'
                        }}>A smartphone app to teach the problem-solving part of GCSE maths questions.</p>
                    </div>
                </div> : 
                <div style={{ display: 'flex', flexDirection: 'row', width: styles(windowDimensions.width).fullPageWidth }}>
                <div style={{ flex: 1, paddingRight: '0.75rem' }}>
                    <div style={{ height: '180px'}}>
                        <p style={{ 
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            fontVariant: 'small-caps',
                            letterSpacong: '0.08rem',
                            borderBottom: 0,
                            color: '#e74c3c',
                            lineHeight: '1.2rem',
                            outline: 'none',
                            fontSize: '1.1rem',
                            marginBottom: '1.5rem',
                            marginLeft: '1em',
                            marginRight: '1em',
                        }}>
                            MATHEMATICS AND PHYSICS PROBLEMS AT THE LEVEL OF UNIVERSITY ENGINEERING ADMISSIONS INTERVIEWS
                        </p>
                    </div>
                    <div style={{ height: '180px'}}>
                        <Link to="/questionlist">
                            <button style={{ 
                                textTransform: 'uppercase', 
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                boxShadow: "0 0.2rem #d62c1a",
                                backgroundColor: "#e74c3c",
                                textShadow: "0 1px #a82315",
                                color: "white",
                                position: 'relative',
                                padding: "1.2rem 1.2rem",
                                marginBottom: "1.0rem",
                                lineHeight: "1.5rem",
                                border: 0,
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                fontFamily: "Clear Sans",
                                outline: "none",
                                textAlign: "center",
                                width: "100%"
                            }}>PROBLEM<br/>INDEX</button>
                        </Link>
                        <p style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.5rem', 
                            margin: 0, 
                            marginBottom: '1.5rem',
                            padding: 0,
                            border: 0,
                            verticalAlign: 'baseline'
                        }}>Choose from over 200 problems.</p>
                    </div>
                    <div style={{ height: '180px'}}>
                        <div>
                            <button onClick={
                                (e) => {
                                    e.preventDefault();
                                    const completedQuestions = Object.fromEntries(
                                        Object.entries(completed).filter(([key, value]) => value === false) )
                                    const randomIndex = Math.floor((Object.keys(completedQuestions).length)*Math.random())
                                    //console.log(Object.keys(completedQuestions).length)
                                    let linkTo = `/q/${Object.keys(completedQuestions)[randomIndex]}`
                                    if (Object.keys(completedQuestions).length*Math.random() === 0) {
                                        linkTo = "/questionlist"
                                    }
                                    
                                    //history.push('/your-route');
                                    history.push(`${linkTo}`)
                                }
                            } style={{ 
                                textTransform: 'uppercase', 
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                boxShadow: "0 0.2rem #d62c1a",
                                backgroundColor: "#e74c3c",
                                textShadow: "0 1px #a82315",
                                color: "white",
                                position: 'relative',
                                padding: "1.2rem 1.2rem",
                                marginBottom: "1.0rem",
                                lineHeight: "1.5rem",
                                border: 0,
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                fontFamily: "Clear Sans",
                                outline: "none",
                                textAlign: "center",
                                width: "100%"
                            }}>PROBLEM<br/>GENERATOR</button>
                        </div>
                        <p style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.5rem', 
                            margin: 0, 
                            marginBottom: '1.5rem',
                            padding: 0,
                            border: 0,
                            verticalAlign: 'baseline'
                        }}>Let the system pick a problem for you based on those you’ve already completed.</p>
                    </div>
                    <div style={{ height: '180px'}}> {/* Usually empty */}
                        
                    </div>
                </div>
                {<div style={{ flex: 1, paddingRight: '0.75rem' }}>
                    <div style={{ height: '180px' }}>
                        <p style={{ 
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            fontVariant: 'small-caps',
                            letterSpacong: '0.08rem',
                            borderBottom: 0,
                            color: '#2980b9',
                            lineHeight: '1.2rem',
                            outline: 'none',
                            fontSize: '1.1rem',
                            marginBottom: '1.5rem',
                            marginLeft: '1em',
                            marginRight: '1em',
                        }}>A-LEVEL MATHEMATICS AND PHYSICS PROBLEMS ADAPTED FROM OCR EXAM PAPERS</p>
                    </div>
                    <div style={{ height: '180px'}}>
                        <Link to="/alevelquestionlist" onClick={(e) => {
                            //e.preventDefault()
                            }}>
                            <button 
                            style={{ 
                                textTransform: 'uppercase', 
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                boxShadow: "0 0.2rem #20638f",
                                backgroundColor: "#2980b9",
                                textShadow: "0 1px #164666",
                                color: "white",
                                position: 'relative',
                                padding: "1.2rem 1.2rem",
                                marginBottom: "1.0rem",
                                lineHeight: "1.5rem",
                                border: 0,
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                fontFamily: "Clear Sans",
                                outline: "none",
                                textAlign: "center",
                                width: "100%"
                            }}>A-LEVEL QUESTION<br/>INDEX</button>
                        </Link>
                        <p style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.5rem', 
                            margin: 0, 
                            marginBottom: '1.5rem',
                            padding: 0,
                            border: 0,
                            verticalAlign: 'baseline'
                        }}>Choose from maths and physics a-level questions.</p>
                    </div>
                    <div style={{ height: '180px'}}>
                        <Link>
                            <button 
                            onClick={(e) => {
                                e.preventDefault();
                                
                                
                                //history.push('/your-route');
                                history.push(`/aq/${aqcompletedorder[0]}`)
                            }}
                            style={{ 
                                textTransform: 'uppercase', 
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                boxShadow: "0 0.2rem #20638f",
                                backgroundColor: "#2980b9",
                                textShadow: "0 1px #164666",
                                color: "white",
                                position: 'relative',
                                padding: "1.2rem 1.2rem",
                                marginBottom: "1.0rem",
                                lineHeight: "1.5rem",
                                border: 0,
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                fontFamily: "Clear Sans",
                                outline: "none",
                                textAlign: "center",
                                width: "100%"
                            }}>NEXT A-LEVEL<br/>QUESTION</button>
                        </Link>
                        <p style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.5rem', 
                            margin: 0, 
                            marginBottom: '1.5rem',
                            padding: 0,
                            border: 0,
                            verticalAlign: 'baseline'
                        }}>Let the system choose your next a-level question.</p>
                    </div>
                    <div style={{ height: '180px'}}>
                        {user ?
                            <button 
                              onClick={() =>
                                auth().signOut()
                              }
                              style={{ 
                                textTransform: 'uppercase', 
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                boxShadow: "0 0.2rem #1e8449",
                                backgroundColor: "#27ae60",
                                textShadow: "0 1px #145b32",
                                color: "white",
                                position: 'relative',
                                padding: "1.2rem 1.2rem",
                                marginBottom: "1.0rem",
                                lineHeight: "1.5rem",
                                border: 0,
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                fontFamily: "Clear Sans",
                                outline: "none",
                                textAlign: "center",
                                width: "100%"
                            }}>LOG OUT<br/>{user.email}</button>
                        : <Link to="/login">
                            <button style={{ 
                                textTransform: 'uppercase', 
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                boxShadow: "0 0.2rem #1e8449",
                                backgroundColor: "#27ae60",
                                textShadow: "0 1px #145b32",
                                color: "white",
                                position: 'relative',
                                padding: "1.2rem 1.2rem",
                                marginBottom: "1.0rem",
                                lineHeight: "1.5rem",
                                border: 0,
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                fontFamily: "Clear Sans",
                                outline: "none",
                                textAlign: "center",
                                width: "100%"
                            }}>LOGIN OR<br/>SIGN UP</button>
                        </Link>}
                        <p style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.5rem', 
                            margin: 0, 
                            marginBottom: '1.5rem',
                            padding: 0,
                            border: 0,
                            verticalAlign: 'baseline'
                        }}>Log in securely to track your progress.</p>
                    </div>
                </div>}
                <div style={{ flex: 1, paddingRight: '0.75rem' }}>
                    <div style={{ height: '180px' }}>
                        <p style={{ 
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            fontVariant: 'small-caps',
                            letterSpacong: '0.08rem',
                            borderBottom: 0,
                            color: '#df9900',
                            lineHeight: '1.2rem',
                            outline: 'none',
                            fontSize: '1.1rem',
                            marginBottom: '1.5rem',
                            marginLeft: '1em',
                            marginRight: '1em',
                        }}>GAMES DESIGNED TO TEACH IDEAS THAT ARE IMPORTANT IN MATHEMATICS AND PHYSICS</p>
                    </div>
                    <div style={{ height: '180px'}}>
                        <a href="https://wiredthegame.com/">
                            <button
                              
                              style={{ 
                                textTransform: 'uppercase', 
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                boxShadow: "0 0.2rem #da7900",
                                backgroundColor: "#df9900",
                                textShadow: "0 1px #a97500",
                                color: "white",
                                position: 'relative',
                                padding: "1.2rem 1.2rem",
                                marginBottom: "1.0rem",
                                lineHeight: "1.5rem",
                                border: 0,
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                fontFamily: "Clear Sans",
                                outline: "none",
                                textAlign: "center",
                                width: "100%"
                            }}>WIRED</button>
                        </a>
                        <p style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.5rem', 
                            margin: 0, 
                            marginBottom: '1.5rem',
                            padding: 0,
                            border: 0,
                            verticalAlign: 'baseline'
                        }}>Wired is first and foremost a fun game, but if you play it, you will also learn about the principles of electrical circuits.</p>
                    </div>
                    <div style={{ height: '180px'}}>
                        
                        <a href={"https://maths.builders/"}>
                            <button 
                            
                            style={{ 
                                textTransform: 'uppercase', 
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                boxShadow: "0 0.2rem #da7900",
                                backgroundColor: "#df9900",
                                textShadow: "0 1px #a97500",
                                color: "white",
                                position: 'relative',
                                padding: "1.2rem 1.2rem",
                                marginBottom: "1.0rem",
                                lineHeight: "1.5rem",
                                border: 0,
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                fontFamily: "Clear Sans",
                                outline: "none",
                                textAlign: "center",
                                width: "100%"
                            }}>MATHS<br/>BUILDERS</button>
                        </a>
                        <p style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.5rem', 
                            margin: 0, 
                            marginBottom: '1.5rem',
                            padding: 0,
                            border: 0,
                            verticalAlign: 'baseline'
                        }}>A smartphone app to teach the problem-solving part of GCSE maths questions.</p>
                    </div>
                    <div style={{ height: '180px'}}>
                        
                    </div>
                </div>
                
                </div>}
            <div style={{ 
                marginBottom: styles(windowDimensions.width).sectionMarginBottom, 
                paddingBottom: '3rem', 
                display: 'flex', 
                flexDirection: 'column', 
                width: styles(windowDimensions.width).fullPageWidth, 
                alignItems: 'center', 
                justifyContent: 'center',
                borderBottomWidth: '0.225rem',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgb(236, 240, 241)'
            }}></div>
            <div style={{ 
                marginBottom: styles(windowDimensions.width).sectionMarginBottom, 
                paddingBottom: '3rem', 
                display: 'flex', 
                flexDirection: 'column', 
                width: styles(windowDimensions.width).fullPageWidth, 
                alignItems: 'center', 
                justifyContent: 'center',
                borderBottomWidth: '0.225rem',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgb(236, 240, 241)'
            }}>
                <h2 style={{ fontSize: '2.2rem', fontWeight: 600, lineHeight: '2.625rem', marginBottom: '1.5rem' }}>Our Aim</h2>
                {false && <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize, fontWeight: 'bold' }}>
                Our aim is to teach you to solve engineering problems and to show that this process can be rewarding and a lot of fun.
                </p>}
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize, fontWeight: 'bold' }}>
                Our aim is to teach you to solve engineering problems and to show that this process can be rewarding and a lot of fun.
                </p>
                {false && <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                School mathematics and physics provide the tools we need, so we offer around 300 questions adapted from OCR A-level papers. There are full video tutorials and a facility to provide email help if you get “confused” (provided you are logged in).
                </p>}
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                You can work on our 211 problems which are designed to reflect some of the challenges in university engineering admissions interviews.  If you get stuck, there are full video tutorials both for the general techniques and for the problems as a whole.
                </p>
                {false && <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                Building on the A-level material, you can work on our 211 more difficult problems. These are designed to reflect some of the challenges in university engineering admissions interviews. Again, there is support in the form of video tutorials and the “confused” button for when you get stuck.
                </p>}
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                For a break, try our innovative educational game Wired. Problem solving is all about practice; the more problems you solve the better you will become, so let’s get started!
                </p>
                {false && <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize}}>
                For a break, try our innovative educational games to learn about electricity, logic, mechanics, materials and more. Problem solving is all about practice; the more problems you solve the better you will become, so let’s get started!
                </p>}
            </div>
            <div style={{ 

                display: 'flex', 
                flexDirection: 'column', 
                width: styles(windowDimensions.width).fullPageWidth, 
                alignItems: 'flex-start', 
                justifyContent: 'flex-start',
            }}>
                <div style={{
                    width: '100%'
                }}>
                    <h2 style={{ textAlign: 'center', fontSize: '2.2rem', fontWeight: 600, lineHeight: '2.625rem', marginBottom: '1.5rem' }}>Advice & Guidance</h2>
                </div>
                <ul style={{ padding: 0 }}>
                    <li style={{ textAlign: 'left', fontSize: '1.3rem' }}>
                    <Link to="/whyeng" style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Why should I study engineering?</Link>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.3rem' }}>
                    <Link to="/choosesubjects" style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Which subjects will best prepare me for an engineering degree?</Link>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: '1.3rem' }}>
                    <Link to="/experience" style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>How do I get on to a good engineering course?</Link>
                    </li>
                </ul>
            </div>
        </div>
        <div style={{ 
            marginBottom: '4.5rem', 
            paddingBottom: '12rem', 
            display: 'flex', 
            flexDirection: 'row', 
            width: styles(windowDimensions.width).fullPageWidth,
            alignItems: 'center', 
            justifyContent: 'center',
            borderTopWidth: '0.225rem',
            borderTopStyle: 'solid',
            borderTopColor: 'rgb(236, 240, 241)'
        }}> 
            <span style={{ marginTop: '1.5rem' }}>
                <Link to="/terms_of_use" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5 }}>Terms of use</Link> · 
                <Link to="/privacy_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Privacy Policy</Link> · 
                <Link to="/cookies_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Cookies Policy</Link> · 
                <Link to="/system_requirements" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>System Requirements</Link> · 
                {/*<Link to="/faq" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>FAQ</Link> · */}
                <Link to="/credits" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingLeft: 5 }}>Credits</Link>
            </span>
        </div>
    </div>
  );
}

export default Home;