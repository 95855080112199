import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";
import { ReactSVG } from 'react-svg'
import { auth, firestore } from "../../services/firebase";
import GlobalState from '../../context/GlobalState';
import context from '../../context/context';
import "fontsource-clear-sans"
import smalllogo from '../../smalllogo.svg';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function styles(width) {
    let fullPageWidth = "80rem"
    let headerFontSize = "3rem"
    let h1FontSize = '3.7rem'
    let orFontSize = '2.2rem'
    let pFontSize = '1.3rem'
    let bottomLinks = '1.1rem'
    let mobile = false
    let sectionMarginBottom = '4.5rem'
    let topLinks = '1.1rem'
    let paddingRight = '3rem'
    let lineHeight = '1.5rem'
    let mobileButton = '20%'
    let h2FontSize = '1.8rem'
    if (width >= 1300) {
        fullPageWidth = "46rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        h2FontSize = '1.8rem'
        mobileButton = '20%'
        mobile = false
    } else if (width < 1300 && width >= 760) {
        fullPageWidth = "46rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        h2FontSize = '1.8rem'
        mobileButton = '40%'
        mobile = false
    } else if (width < 760 ) {
        fullPageWidth = "20rem"
        headerFontSize = "1.75rem"
        pFontSize = '1rem'
        h2FontSize = '1.4rem'
        h1FontSize = '2.5rem'
        orFontSize = '1.5rem'
        mobileButton = '100%'
        mobile = true
        sectionMarginBottom  = '2rem'
        topLinks = '0.9rem'
        paddingRight = '1rem'
        lineHeight = '1.1rem'
    }
    return { fullPageWidth, h2FontSize, headerFontSize, pFontSize, mobile, sectionMarginBottom, h1FontSize, orFontSize, topLinks, paddingRight, lineHeight, mobileButton }
}

function Experience() {
    const history = useHistory();
    const { completed, setAllCompleted } = useContext(context);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column',  width: windowDimensions.width-(styles(windowDimensions.width).mobile ? 8 : 64), flex: 1, alignItems: 'center', height: '100vh', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", margin: 'auto' }}>
        <div style={{ 
            marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '6rem',
            display: 'flex', 
            flexDirection: 'row', 
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)',
            width: '100%'
        }}>
            <div style={{ display: 'flex', flex: 2, height: '4.5rem' }}>
                <Link to="/"> 
                    <ReactSVG beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('style', 'height: 4.5rem; width: 4rem; padding: 0.75rem 0 0.75rem 0.75rem')
                    }} src={smalllogo} style={{marginBottom: '0.75rem'}} />
                </Link>
            </div>
            <div style={{ display: 'flex', flex: 4, alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: '-1rem', display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, color: '#000' }} to="/questionlist">
                        Problem Index
                    </Link>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault();
                        const completedQuestions = Object.fromEntries(
                            Object.entries(completed).filter(([key, value]) => value === false) )
                        //console.log(Object.keys(completedQuestions).length*Math.random())
                        const randomIndex = Math.floor((Object.keys(completedQuestions).length)*Math.random())
                        
                        //history.push('/your-route');
                        history.push(`/q/${Object.keys(completedQuestions)[randomIndex]}`)
                    }}>
                        Problem Generator
                    </Link>
                    {auth().currentUser ? 
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault()
                        auth().signOut()
                    }}>{`Logout`}<br />{auth().currentUser.email}</Link>
                    : <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} to="/login">
                        {`Login or Sign Up`}
                    </Link>}
                </div>
            </div>
        </div>
        <div style={{ 
            marginBottom: '2rem', 
            paddingBottom: '0rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: styles(windowDimensions.width).fullPageWidth, 
            alignItems: 'center', 
            justifyContent: 'center',
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)'
        }}>
            <h1 style={{ fontSize: styles(windowDimensions.width).h1FontSize, fontWeight: 600, lineHeight: '4.5rem', marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '4.5rem', width: '100%', textAlign: 'center' }}>How do I get in?</h1>
        </div>
        <div style={{ 
                marginBottom: styles(windowDimensions.width).sectionMarginBottom, 
                paddingBottom: '3rem', 
                display: 'flex', 
                flexDirection: 'column', 
                width: styles(windowDimensions.width).fullPageWidth, 
                alignItems: 'center', 
                justifyContent: 'center',
                borderBottomWidth: '0.225rem',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgb(236, 240, 241)'
            }}>
                <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>The list below shows the main skills that will enable you to make a success of a university engineering course. You will pick up some of them at school and you can supplement your problem solving using this web site.</h2>
        </div>
        <div style={{ 
                marginBottom: styles(windowDimensions.width).sectionMarginBottom, 
                paddingBottom: '3rem', 
                display: 'flex', 
                flexDirection: 'column', 
                width: styles(windowDimensions.width).fullPageWidth, 
                alignItems: 'center', 
                justifyContent: 'center',
                borderBottomWidth: '0.225rem',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgb(236, 240, 241)'
            }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: 16, marginRight: 16 }}>
                <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.5rem', marginBottom: '1rem' }}>An interest in engineering</h2> 
                <p style={{ fontSize: styles(windowDimensions.width).pFontSize, fontWeight: 400, lineHeight: '2rem'}}>Motivation is vital to successfully complete a degree. At school it is often clear what work needs to be done. At university there is more freedom and it is up to you to take advantage of the various educational opportunities that are on offer. Highly motivated students thus get the most out of university.</p>   
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: 16, marginRight: 16 }}>
                <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.5rem', marginBottom: '1rem' }}>Knowledge and understanding of maths and physics</h2> 
                <p style={{ fontSize: styles(windowDimensions.width).pFontSize, fontWeight: 400, lineHeight: '2rem'}}>You need to have the right background knowledge to start the course. Don’t confine your knowledge rigidly to the syllabus defined by your exam board. University study involves considering subjects more broadly.</p>   
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: 16, marginRight: 16 }}>
                <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.5rem', marginBottom: '1rem' }}>Relate your studies to the real world</h2> 
                <p style={{ fontSize: styles(windowDimensions.width).pFontSize, fontWeight: 400, lineHeight: '2rem'}}>At interview it will probably count in your favour if you demonstrate that you understand the practical consequences of the material that you have been taught in maths and science.</p>  
            </div>
        </div>
        <div style={{ 
                marginBottom: styles(windowDimensions.width).sectionMarginBottom, 
                paddingBottom: '3rem', 
                display: 'flex', 
                flexDirection: 'column', 
                width: styles(windowDimensions.width).fullPageWidth, 
                alignItems: 'center', 
                justifyContent: 'center',
                borderBottomWidth: '0.225rem',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgb(236, 240, 241)'
            }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: 16, marginRight: 16 }}>
                <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.5rem', marginBottom: '1rem' }}>Problem solving skills</h2> 
                <p style={{ fontSize: styles(windowDimensions.width).pFontSize, fontWeight: 400, lineHeight: '2rem'}}>Professional work in engineering often involves solving problems. It is good to get experience with problem solving, either in practice or in theory. This web site provides a source of problems for you to try.</p>   
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: 16, marginRight: 16 }}>
                <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.5rem', marginBottom: '1rem' }}>Communication and inter-personal skills</h2> 
                <p style={{ fontSize: styles(windowDimensions.width).pFontSize, fontWeight: 400, lineHeight: '2rem'}}>A lot of engineering involves communication and teamwork. Engineers often have to win contracts by convincing the client that they are the best candidate to do the work. They then have to manage their team to ensure that the work is completed in time and on budget.</p>   
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: 16, marginRight: 16 }}>
                <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.5rem', marginBottom: '1rem' }}>An ability to work independently and efficiently</h2> 
                <p style={{ fontSize: styles(windowDimensions.width).pFontSize, fontWeight: 400, lineHeight: '2rem'}}>Elite engineering courses involve a lot of work. You need to be able to get through your work efficiently and still leave time for relaxation and a balanced lifestyle. This requires an organised approach, self-discipline and time management skills.</p>  
            </div>
        </div>
        <div style={{ 
                marginBottom: styles(windowDimensions.width).sectionMarginBottom, 
                paddingBottom: '3rem', 
                display: 'flex', 
                flexDirection: 'column', 
                width: styles(windowDimensions.width).fullPageWidth, 
                alignItems: 'center', 
                justifyContent: 'center',
                borderBottomWidth: '0.225rem',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgb(236, 240, 241)'
            }}>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    A good opportunity to gain further relevant skills can come from a job, either in your school holidays, or during a gap-year between school and university. The <a href="https://www.etrust.org.uk/the-year-in-industry"
                    style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Engineering Development Trust</a> organise the Year in Industry Scheme to help gap-students get jobs. They also run Headstart courses for 16 and 17 year old students. Other opportunities are provided by the <a href="https://www.smallpeicetrust.org.uk/"
                    style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Smallpeice Trust</a> and the <a href="http://www.suttontrust.com/home/"
                    style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Sutton Trust</a>.
                </p>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    Need more advice? Read <Link to="/whyeng" style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>“Why should I study engineering?”</Link>.
                </p>
        </div>
        <div style={{ 
            marginBottom: '4.5rem', 
            paddingBottom: '12rem', 
            display: 'flex', 
            flexDirection: 'row', 
            width: styles(windowDimensions.width).fullPageWidth,
            alignItems: 'center', 
            justifyContent: 'center',
        }}> 
            <span style={{ marginTop: '1.5rem' }}>
                <Link to="/terms_of_use" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5 }}>Terms of use</Link> · 
                <Link to="/privacy_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Privacy Policy</Link> · 
                <Link to="/cookies_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Cookies Policy</Link> · 
                <Link to="/system_requirements" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>System Requirements</Link> · 
                {/*<Link to="/faq" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>FAQ</Link> · */}
                <Link to="/credits" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingLeft: 5 }}>Credits</Link>
            </span>
        </div>
    </div>
  );
}

export default Experience;