import React, { useEffect, useState, createRef, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom';
//require('typeface-clear-sans')
import "fontsource-clear-sans"
import smalllogo from '../../smalllogo.svg';
import { ReactSVG } from 'react-svg'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";
import { firestore, storage } from "../../services/firebase"
import { auth } from "../../services/firebase";
import MathJax from 'react-mathjax-preview'
import GlobalState from '../../context/GlobalState';
import context from '../../context/context';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const confusedIds = ["calculus_ln_s","circ_motion_eqns_s","conical_pendulum_s","gen_1_stretch_of_a_curve_s","log_operations_s","moment_of_inertia_s","res_bridge_s","spec_aircraft_drink_s","spec_arcs_inside_a_square_s","spec_bacteria_s","spec_ball_bag_s","spec_ball_in_oil_s","spec_bicycle_turning_s","spec_bowl_of_sweets_s","spec_bungee_bounce_frequency_s","spec_bungee_jumper_s","spec_capacitor_switch_s","spec_capacitors_and_diodes_s","spec_children_s","spec_clock_s","spec_cogs_s","spec_coin_order_s","spec_combining_capacitors_s","spec_const_work2_s","spec_differential_equation_2_s","spec_differentiation_of_arctan_s","spec_earthed_loops_s","spec_eggs_2_s","spec_forces_on_a_body_s","spec_four_spheres_s","spec_gravitational_pull_s","spec_high_voltage_sphere_s","spec_hole_in_sheet_s","spec_hot_air_balloon_s","spec_ice_cube_s","spec_icing_doughnuts_s","spec_infinite_loops_circuit_2_s","spec_integral_of_fraction_s","spec_jumping_box_s","spec_lego_tower_s","spec_lifeguard_s","spec_linear_momentum_s","spec_moment_of_inertia_s","spec_momentum_s","spec_moon_about_earth_s","spec_mortgage_s","spec_nested_square_roots_s","spec_number_of_roots_s","spec_orchestra_tuning_s","spec_parallel_wires_s","spec_particle_on_a_hemisphere_s","spec_particles_on_a_spring_s","spec_party_s","spec_pdf_s","spec_person_in_lift_s","spec_pile_of_blocks_s","spec_power_dist_s","spec_pulley_dynamics_1_s","spec_random_number_generator_s","spec_res_cube_s","spec_resistor_and_capacitor_s","spec_rocket_s","spec_roll_a_die_s","spec_rolling_toy_s","spec_ruler_on_cube_s","spec_simple_projectile_s","spec_simultaneous_equations_s","spec_sliding_in_air_s","spec_sock_pairs_s","spec_stick_and_planks_s","spec_strat_balan_s","spec_sum_three_dice_s","spec_three_by_three_inverse_s","spec_three_by_three_matrix_s","spec_toilet_paper_s","spec_weird_resistor_s","what do i do next"]


function styles(width) {
    let fullPageWidth = "80rem"
    let headerFontSize = "3rem"
    let h1FontSize = '3.7rem'
    let orFontSize = '2.2rem'
    let pFontSize = '1.3rem'
    let bottomLinks = '1.1rem'
    let mobile = false
    let sectionMarginBottom = '4.5rem'
    let topLinks = '1.1rem'
    let paddingRight = '3rem'
    let lineHeight = '1.5rem'
    let mobileButton = '20%'
    if (width >= 1300) {
        fullPageWidth = "80rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        mobileButton = '20%'
        mobile = false
    } else if (width < 1300 && width >= 760) {
        fullPageWidth = "60rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        mobileButton = '40%'
        mobile = false
    } else if (width < 760 ) {
        fullPageWidth = "20rem"
        headerFontSize = "1.75rem"
        pFontSize = '1rem'
        h1FontSize = '2.5rem'
        orFontSize = '1.5rem'
        mobileButton = '100%'
        mobile = true
        sectionMarginBottom  = '2rem'
        topLinks = '0.9rem'
        paddingRight = '1rem'
        lineHeight = '1.1rem'
    }
    return { fullPageWidth, headerFontSize, pFontSize, mobile, sectionMarginBottom, h1FontSize, orFontSize, topLinks, paddingRight, lineHeight, mobileButton }
}

function Question() {
  const history = useHistory();
  const ref = createRef()
  const [selectedVideo, setSelectedVideo] = useState(-1)
  const [questions, setQuestions] = useState([])
  const [title, setTitle] = useState("")
  const [questionText, setQuestionText] = useState([])
  const [imageUri, setImageUri] = useState("")
  let { questionId } = useParams();
  const [showSummary, setShowSummary] = useState(-1); 
  const [showVideo, setShowVideo] = useState(-1); 
  const [showSummaryData, setShowSummaryData] = useState([])
  const [answers, setAnswers] = useState([])
  const [answerFeedback, setAnswerFeedback] = useState('')
  const [questionCorrectAttempts, setQuestionCorrectAttempts] = useState(0)
  const [showCorrectVideo, setShowCorrectVideo] = useState(false)
  const [answerButton, setAnswerButton] = useState(-1)
  const [answerBooleans, setAnswerBooleans] = useState([])
  const [hintTitle, setHintTitle] = useState([])
  const [hintMap, setHintMap] = useState({})
  const [playing, setPlaying] = useState(true)
  const [localQuestionData, setLocalQuestionData] = useState({})
  const [controlsShow, setControlsShow] = useState(false)
  const [hasSelectedCorrect, setHasSelectedCorrect] = useState(false);
  const [questionAttempts, setQuestionAttempts] = useState(0)
  const [answerButtonReset, setAnswerButtonReset] = useState(true)
  const [videoQuestionAttempts, setVideoQuestionAttempts] = useState(0)
  const [isVideoCorrect, setIsVideoCorrect] = useState(false)
  const [isVideoIncorrect, setIsVideoIncorrect] = useState(false)
  const [returnedVideoUrl, setReturnedVideoUrl] = useState("")
  const [returnedSummaryUrl, setReturnedSummaryUrl] = useState("")
  const [confusedOpen, setConfusedOpen] = useState(false)
  const [confusedObject, setConfusedObject] = useState(null)
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const {completed, setQuestionCompleted} = useContext(context)
  const [vQuestionIndex, setVQuestionIndex] = useState(null)

  /*const hintTitle = [
    {linkTitle: "overall", rowTitle: "Overall solution strategy"},
    {linkTitle: "moments", rowTitle: "Moments"},
    {rowTitle: "Gravity", linkTitle: "gravity"}
  ]*/

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
}, []);

  const videoUrl = async (filename) => {
    let url = await storage.ref(`lqvideo/${filename}.mp4`).getDownloadURL()
    return url
  }

  const summaryUrl = async (filename) => {
    let url = await storage.ref(`lqimages/${filename}`).getDownloadURL()
    return url
  }

  const isCorrect = async (correct) => {
      if (correct) {
        const questionCorrectAttemptsIterator = questionCorrectAttempts+1
        setQuestionCorrectAttempts(questionCorrectAttemptsIterator)
        if (questionCorrectAttempts%4 === 0) {
            setShowCorrectVideo(true)
        }
        setQuestionCompleted(questionId)
        //TODO: need to resolve this for non-logged in users.
        if (auth().currentUser) {
            await firestore.collection('Users').doc(auth().currentUser.uid).update({
                [`completed.${questionId}`]: true
            });
        }
        return true
      }
      return false
  }

  function getVideoQuestion(data, id) {
    let questionKeys = Object.keys(data)
    let variant_1 = ["boat", "house"]
    let variant_2 = ["house", "address"]
    let variant_3 = ["va", "vb"]
    let variant_4 = ["series", "parallel"]
    let key = "house"
    let keys = ["house"]
    if (questionKeys.includes("boat")) {
        keys = variant_1 
        key = keys[id]
    }
    if (questionKeys.includes("address")) {
        keys = variant_2 
        key = keys[id]
    }
    if (questionKeys.includes("va")) {
        keys = variant_3
        key = keys[id]
    }
    if (questionKeys.includes("series")) {
        keys = variant_4
        key = keys[id]
    }
    return { "answer": data[key].answer, "buttonCheckTop": data[key].buttonCheck.top, "buttonCheckLeft": data[key].buttonCheck.left, "buttonContinueTop": data[key].buttonContinue.top, "buttonContinueLeft": data[key].buttonContinue.left, "toastCorrectTop": data[key].toastCorrect.top, "toastCorrectLeft": data[key].toastCorrect.left }
  }

  useEffect(async () => {
    //console.log("questionId", questionId)
    const snapshot = await firestore.collection(`Questions`).doc(questionId).get();
    //console.log("snapshot", snapshot)
    const questionData = snapshot.data();
    if (questionData) {
        //console.log("questionData", questionData)
        setLocalQuestionData(questionData)
        //console.log(questionData.balances.balances)
        //console.log(JSON.stringify(questionData[questionId]));
        setHintTitle(questionData[questionId].hint.title)
        //TODO: some stuff with hint video => questionData[questionId].hint.video
        const blankObj = {}
        questionData[questionId].hint.video.forEach((video) => {
            if (!blankObj[video.link]) {
                blankObj[video.link] = [video]
            } else {
                blankObj[video.link].push(video)
            }
        })
        setHintMap(blankObj)
        setTitle(questionData[questionId].questions.title)
        //console.log(questionData[questionId].questions.fullquestion.question.split("<br/>"))
        setQuestionText(questionData[questionId].questions.fullquestion.question.split("<br/>"))
        setImageUri(questionData[questionId].questions.fullquestion.image)
        setShowSummaryData(questionData[questionId].hint.video)
        setAnswers(questionData[questionId].questions.fullquestion.answer)
        setAnswerBooleans(questionData[questionId].questions.fullquestion.answer.map((a) => a.correct))

        const showSummaryArray = questionData[questionId].hint.video.map((video) => {
            return false
        });
        setShowSummary(showSummaryArray)
    } else {
        history.push(`/`)
    }
    //setQuestionText()
  }, []);

  return (
    <div style={{ overflowX: styles(windowDimensions.width).mobile ? "hidden" : "visible" }}>
    <div style={{ display: 'flex', flexDirection: 'column',  width: windowDimensions.width-(styles(windowDimensions.width).mobile ? 8 : 64), margin: 'auto', flex: 1, alignItems: 'flex-start', height: '100vh', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif" }}>
        <div style={{ 
            marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '6rem', 
            display: 'flex', 
            flexDirection: 'row', 
            width: '100%', 
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)'
        }}>
            <div style={{ display: 'flex', flex: 2, height: '4.5rem' }}>
                <Link to="/"> 
                    <ReactSVG beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('style', 'height: 4.5rem; width: 4rem; padding: 0.75rem 0 0.75rem 0.75rem')
                    }} src={smalllogo} style={{marginBottom: '0.75rem'}} />
                </Link>
            </div>
            <div style={{ display: 'flex', flex: 4, alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ display: 'flex', marginRight: '-1rem', flexDirection: 'row', alignItems: 'center' }}>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} to="/questionlist">
                        Problem Index
                    </Link>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault();
                        const completedQuestions = Object.fromEntries(
                            Object.entries(completed).filter(([key, value]) => value === false) )
                        //console.log(Object.keys(completedQuestions).length*Math.random())
                        const randomIndex = Math.floor((Object.keys(completedQuestions).length)*Math.random())
                        
                        //history.push('/your-route');
                        history.push(`/q/${Object.keys(completedQuestions)[randomIndex]}`)
                    }}>
                        Problem Generator
                    </Link>
                    {auth().currentUser ? 
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault()
                        auth().signOut()
                    }}>{`Logout`}<br />{auth().currentUser.email}</Link>
                    : <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} to="/login">
                        {`Login or Sign Up`}
                    </Link>}
                </div>
            </div>
        </div>
        <div style={{ 
            marginBottom: '2rem', 
            paddingBottom: '0rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: '100%', 
            alignItems: 'center', 
            justifyContent: 'center'
        }}>
            <h1 style={{ fontSize: styles(windowDimensions.width).h1FontSize, fontWeight: 600, lineHeight: '4.5rem', marginBottom: '0rem', width: '100%', textAlign: 'center' }}>{title} problem</h1>
        </div>
        <div style={{ zoom: 1, width: '100%', marginRight: '-0.75rem', marginTop: 0, marginBottom: 0, marginLeft: 0, padding: 0, border: 0, fontSize: '100%', verticalAlign: 'baseline' }}>
            <div style={{ width: styles(windowDimensions.width).mobile ? '100%' : '50%', float: 'left', overflow: 'hidden', paddingRight: styles(windowDimensions.width).mobile ? '0rem' : '0.75rem', paddingBottom: styles(windowDimensions.width).mobile ? '0rem' : '0.75rem', paddingTop: 0, paddingLeft: 0, margin: 0, border: 0, fontSize: '100%', verticalAlign: 'baseline' }} className="g-half-pad  g-one-whole  g-L-one-half">
                <div style={{ marginBottom: '1.5rem', zoom: 1, margin: 0, padding: 0, border: 0, fontSize: '100%', verticalAlign: 'baseline' }} className="question  cf">
                    <h2 style={{ textAlign: 'center', fontSize: '2.2rem', marginBottom: '1.5rem', lineHeight: '2.625rem', fontWeight: 600 }}>Question</h2>
                    <div style={{ backgroundColor: '#ecf0f1', borderRadius: '0.3rem', marginBottom: '1.5rem', paddingTop: '0.75rem', paddingRight: '0.75rem', paddingBottom: '0.75rem', paddingLeft: '0.75rem' }} className="islet  island--grey">
                        <div style={{ marginBlockStart: '1em', marginBlockEnd: '1em', marginInlineStart: styles(windowDimensions.width).mobile ? 0 : 40, marginInlineEnd: styles(windowDimensions.width).mobile ? 0 : 40, display: 'flex', justifyContent: 'center' }}>
                            {imageUri && <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} className="img-inline  problem-img" src={imageUri} />}
                        </div>
                        {questionText.map((text) => {
                            return (<div style={{ marginBottom: '1.5rem' }}>    
                                <MathJax style={{ fontSize: '1.3rem', lineHeight: '1.875rem', marginBlockStart: '1em', marginBlockEnd: '1em', marginInlineStart: 0, marginInlineEnd: 0 }}math={text} />
                            </div>)
                        })}
                    </div>
                </div>
            </div>
            <div style={{ width: styles(windowDimensions.width).mobile ? '100%' : '50%', float: 'left', overflow: 'hidden', paddingRight: styles(windowDimensions.width).mobile ? '0rem' : '0.75rem', paddingBottom: styles(windowDimensions.width).mobile ? '0rem' : '0.75rem', paddingTop: 0, paddingLeft: 0, margin: 0, border: 0, fontSize: '100%', verticalAlign: 'baseline' }} className="g-half-pad  g-one-whole  g-L-one-half">
                <div style={{ marginBottom: '1.5rem', zoom: 1, margin: 0, padding: 0, border: 0, fontSize: '100%', verticalAlign: 'baseline' }} className="question  cf">
                    <h2 style={{ textAlign: 'center', fontSize: '2.2rem', marginBottom: '1.5rem', lineHeight: '2.625rem', fontWeight: 600 }}>Hints</h2>
                    <div style={{ backgroundColor: '#ecf0f1', borderRadius: '0.3rem', marginBottom: '1.5rem', paddingTop: '0.75rem', paddingRight: '0.75rem', paddingBottom: '1.5rem', paddingLeft: '0.75rem', height: 'auto' }} id="hint_table" className="islet  island--grey">
                        <div style={{ zoom: 1, marginRight: '-0.75rem', marginLeft: 0, marginTop: 0, marginBottom: 0, padding: 0, border: 0, fontSize: '100%' }} className="gw-half-pad">
                            <div style={{ left: '20%', width: '40%', overflow: 'hidden', paddingRight: '0.75rem', paddingBottom: '0.75rem', position: 'relative', float: 'left' }} className="g-half-pad  g-one-half  g-S-pre-one-fifth  g-S-two-fifths">
                                <p style={{ fontSize: '1.1rem', lineHeight: 1.6875, marginBottom: '1.5rem', textAlign: 'center', fontWeight: 400, color: '#2980b9' }} className="hints__column-title--general">General</p>
                            </div> 
                            <div style={{ left: '20%', width: '40%', overflow: 'hidden', paddingRight: '0.75rem', paddingBottom: '0.75rem', position: 'relative' }} className="g-half-pad  g-one-half  g-S-pre-one-fifth  g-S-two-fifths">
                                <p style={{ fontSize: '1.1rem', lineHeight: 1.6875, marginBottom: '1.5rem', textAlign: 'center', fontWeight: 400, color: '#27ae60' }} className="hints__column-title--specific">Problem specific</p>
                            </div>
                        </div>
                        {hintTitle.length > 0 && hintTitle.map((hintTitleItem, index) => <div style={{ zoom: 1, marginRight: '-0.75rem', marginLeft: 0, marginTop: 0, marginBottom: 0, padding: 0, border: 0, fontSize: '100%',  boxSizing: 'border-box', display: 'flex' }} className="gw-half-pad">
                            <div style={{ width: '20%', float: 'left', overflow: 'hidden', paddingRight: '0.75rem', paddingBottom: '0.75rem', paddingLeft: 0, paddingTop: 0, margin: 0, border: 0, fontSize: '100%', position: 'relative', verticalAlign: 'baseline' }}  className="g-half-pad  g-one-whole  g-S-one-fifth">
                                <MathJax style={{ fontWeight: 400, marginTop: 0, marginLeft: 0, marginRight: 0, marginBottom: 0, padding: 0, border: 0, fontSize: '1.1rem', lineHeight: 1.6875, verticalAlign: 'baseline' }} className="hints__title" math={hintTitleItem.rowTitle} />
                            </div>
                            <div style={{ paddingTop: 0, paddingLeft: 0, margin: 0, border: 0, paddingBottom: '0.25rem', width: '78%', display: 'block', float: 'left', overflow: 'hidden', paddingRight: '0.75rem', verticalAlign: 'baseline' }} className="g-half-pad  g-one-whole  g-S-four-fifths">
                                <div style={{zoom: 1, marginRight: '-0.75rem', marginLeft: 0, marginVertical: 0, padding: 0, border: 0, verticalAlign: 'baseline' }} className="g-half-pad">
                                    <div style={{marginBottom: '1.5rem', width: '50%', display: 'block', float: 'left', overflow: 'hidden', paddingLeft: 0, paddingTop: 0, margin: 0, border: 0, verticalAlign: 'baseline', paddingRight: '0.75rem', paddingBottom: '0.75rem', position: 'relative'}} className="g-half-pad  g-one-half  m-L-b-1">
                                        {hintMap[hintTitleItem.linkTitle] && hintMap[hintTitleItem.linkTitle][0].title.length > 0 && <div style={{ borderColor: '#94c6e7', borderStyle:'solid', borderWidth: '0.225rem', borderRadius: '0.3rem', paddingTop: '0.375rem', paddingBottom: 0, paddingLeft: '0.375rem', paddingRight: '0.375rem' }} className="islet-S  island--blue">
                                            <div onClick={async (e) => {
                                                const trueIndex = index*2

                                                const localUrl = await videoUrl(hintMap[hintTitleItem.linkTitle][0].title) //showSummaryData[showVideo].title)
                                                setReturnedVideoUrl(localUrl)
                                                setShowVideo(trueIndex)
                                            }} style={{ width: '100%', textDecoration: 'none', borderBottom: 0, textTransform: 'uppercase', fontVariant: 'small-caps', letterSpacing: '0.08rem', fontSize: '1.1rem', boxShadow: '0 0.2rem #20638f', backgroundColor: '#2980b9', textShadow: '0 1px #164666', color: 'white', position: 'relative', marginRight: 'auto', marginLeft: 'auto', padding: '0.85714rem', marginBottom: '0.575rem', lineHeight: '1.5rem', border: 0, borderRadius: '0.3rem', cursor: 'pointer', display: 'blog', outline: 'none', textAlign: 'center' }} className="btn  btn--quarter-marg  btn--XS  btn--push  btn--push--3  btn--center"  name="modal">Video</div>
                                            {confusedIds.includes(hintMap[hintTitleItem.linkTitle][0].title) && <div style={{ width: 20, height: 20, position: "absolute", top: 6, right: 18, backgroundColor: 'red', borderColor: 'red', borderStyle:'solid', borderWidth: '0.225rem', borderRadius: '2rem' }} />}
                                            <div onClick={async () => {
                                                const trueIndex = index*2
                                                const localUrl = await summaryUrl(`${hintMap[hintTitleItem.linkTitle][0].title}.jpg`) //showSummaryData[showVideo].title)
                                                
                                                setReturnedSummaryUrl(localUrl)
                                                setShowSummary(trueIndex)
                                            }} style={{ width: '100%', textDecoration: 'none', borderBottom: 0, textTransform: 'uppercase', fontVariant: 'small-caps', letterSpacing: '0.08rem', fontSize: '1.1rem', boxShadow: '0 0.2rem #20638f', backgroundColor: '#2980b9', textShadow: '0 1px #164666', color: 'white', position: 'relative', marginRight: 'auto', marginLeft: 'auto', padding: '0.85714rem', marginBottom: '0.575rem', lineHeight: '1.5rem', border: 0, borderRadius: '0.3rem', cursor: 'pointer', display: 'blog', outline: 'none', textAlign: 'center' }} className="btn  btn--quarter-marg  btn--XS  btn--push  btn--push--3  btn--center" name="summary" tabIndex="2">Summary</div>
                                        </div>}
                                    </div>
                                    <div style={{marginBottom: '1.5rem', width: '50%', display: 'block', float: 'left', overflow: 'hidden', paddingLeft: 0, paddingTop: 0, margin: 0, border: 0, verticalAlign: 'baseline', paddingRight: '0.75rem', paddingBottom: '0.75rem', position: 'relative'}} className="g-half-pad  g-one-half  m-L-b-1">
                                        {hintMap[hintTitleItem.linkTitle] && hintMap[hintTitleItem.linkTitle][1].title.length > 0 && <div style={{ borderColor: '#89e5b0', borderStyle:'solid', borderWidth: '0.225rem', borderRadius: '0.3rem', paddingTop: '0.375rem', paddingBottom: 0, paddingLeft: '0.375rem', paddingRight: '0.375rem' }} className="islet-S  island--blue">
                                            <div onClick={async () => {
                                                const trueIndex = (index*2) + 1
                                                
                                                const localUrl = await videoUrl(hintMap[hintTitleItem.linkTitle][1].title) //showSummaryData[showVideo].title)
                                                setReturnedVideoUrl(localUrl)
                                                setShowVideo(trueIndex)
                                            }} style={{ width: '100%', textDecoration: 'none', borderBottom: 0, textTransform: 'uppercase', fontVariant: 'small-caps', letterSpacing: '0.08rem', fontSize: '1.1rem', boxShadow: '0 0.2rem #1e8449', backgroundColor: '#27ae60', textShadow: '0 1px #145b32', color: 'white', position: 'relative', marginRight: 'auto', marginLeft: 'auto', padding: '0.85714rem', marginBottom: '0.575rem', lineHeight: '1.5rem', border: 0, borderRadius: '0.3rem', cursor: 'pointer', display: 'blog', outline: 'none', textAlign: 'center' }} className="btn  btn--quarter-marg  btn--XS  btn--push  btn--push--3  btn--center" name="modal">Video</div>
                                            {confusedIds.includes(hintMap[hintTitleItem.linkTitle][1].title) && <div style={{ width: 20, height: 20, position: "absolute", top: 6, right: 18, backgroundColor: 'red', borderColor: 'red', borderStyle:'solid', borderWidth: '0.225rem', borderRadius: '2rem' }} />}
                                            <div onClick={async () => {
                                                const trueIndex = (index*2) + 1
                                                let title = hintMap[hintTitleItem.linkTitle][1].title
                                                if (title === "spec_derive_suvat_s") {
                                                    title = "spec_derive_SUVAT_s"
                                                }
                                                const localUrl = await summaryUrl(`${title}.jpg`) //showSummaryData[showVideo].title)
                                                
                                                setReturnedSummaryUrl(localUrl)
                                                setShowSummary(trueIndex)
                                            }} style={{ width: '100%', textDecoration: 'none', borderBottom: 0, textTransform: 'uppercase', fontVariant: 'small-caps', letterSpacing: '0.08rem', fontSize: '1.1rem', boxShadow: '0 0.2rem #1e8449', backgroundColor: '#27ae60', textShadow: '0 1px #145b32', color: 'white', position: 'relative', marginRight: 'auto', marginLeft: 'auto', padding: '0.85714rem', marginBottom: '0.575rem', lineHeight: '1.5rem', border: 0, borderRadius: '0.3rem', cursor: 'pointer', display: 'blog', outline: 'none', textAlign: 'center' }} className="btn  btn--quarter-marg  btn--XS  btn--push  btn--push--3  btn--center" name="summary" tabIndex="2">Summary</div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', float: 'left', overflow: 'hidden', paddingRight: styles(windowDimensions.width).mobile ? '0rem' : '0.75rem', paddingBottom: styles(windowDimensions.width).mobile ? '0rem' : '0.75rem', paddingTop: 0, paddingLeft: 0, margin: 0, border: 0, fontSize: '100%', verticalAlign: 'baseline' }} className="g-half-pad  g-one-whole  g-L-one-half">
                <div style={{ marginBottom: '1.5rem', zoom: 1, margin: 0, padding: 0, border: 0, fontSize: '100%', verticalAlign: 'baseline' }} className="question  cf">
                    <h2 style={{ textAlign: 'center', fontSize: '2.2rem', marginBottom: '1.5rem', lineHeight: '2.625rem', fontWeight: 600 }}>Answer</h2>
                    <div style={{ backgroundColor: '#ecf0f1', borderRadius: '0.3rem', marginBottom: '5rem', paddingTop: '0.75rem', paddingRight: '0.75rem', paddingBottom: '1.5rem', paddingLeft: '0.75rem', display: 'flex', flexDirection: 'column' }} id="hint_table" className="islet  island--grey">
                        <div style={{ display: 'flex', flex: 1, flexWrap: 'wrap'}}>
                        {answers.map((answer, index) => {
                            return <div onClick={() => {
                                if (answerButton > -1 && answerButton !== index) {
                                    setAnswerButtonReset(true)
                                }
                                //isCorrect(answer.correct)
                                setAnswerButton(index)

                            }} style={{ 
                                width: styles(windowDimensions.width).mobile ? '100%' : '50%',
                                display: 'block',
                                float: 'left',
                                overflow: 'hidden',
                                paddingRight: styles(windowDimensions.width).mobile ? '0rem' : '0.75rem',
                                paddingBottom: '0.75rem',
                                position: 'relative'
                            }}>
                                <input style={{
                                    display: 'none',
                                    speak: 'none',
                                    marginBottom: '1.5rem'
                                }} />
                                <label style={{
                                    textDecoration: 'none',
                                    borderBottom: 0,
                                    fontSize: '1.1rem',
                                    paddingLeft: '1.5rem',
                                    paddingRight: '1.5rem',
                                    paddingTop: '1.5rem',
                                    paddingBottom: '1.3rem',
                                    backgroundColor: answerButton === index ? '#617374' : '#95a5a6',
                                    boxShadow:'0 0.2rem #798d8f',
                                    color: '#FFFFFF',
                                    textShadow: '0 0.075rem #617374',
                                    marginBottom: '0.2rem',
                                    lineHeight: '1.5rem',
                                    border: 0,
                                    borderRadius: '0.3rem',
                                    cursor: 'pointer',
                                    display: 'block',
                                    fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
                                    outline: 'none',
                                    textAlign: 'center',
                                    fontWeight: '600'
                                }}>{answer.text.includes("https") ? <img src={answer.text} style={{ width: '100%' }} /> : <MathJax math={answer.text} />}</label>
                            </div>
                        })}
                        </div>
                        {(questionAttempts > 0 && hasSelectedCorrect === false && answerButtonReset === false) && (<div style={{ display: 'flex', flex: 1 }}>
                            <input type="submit" value="Wrong, try again" style={{ 
                                textDecoration: 'none', 
                                borderBottom: 0,  
                                textTransform: 'uppercase',
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginBottom: '1.0rem',
                                lineHeight: '1.5rem',
                                width: styles(windowDimensions.width).mobileButton,
                                border: 0,
                                borderRadius: '0.3rem',
                                cursor: 'pointer',
                                display: 'block',
                                fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
                                outline: 'none',
                                padding: '1.875rem',
                                textAlign: 'center',
                                backgroundColor: '#e74c3c',
                                boxShadow:'0 0.2rem #d62c1a',
                                color: '#FFFFFF',
                                textShadow: '0 1px #a82315',
                                position: 'relative'
                            }} />
                        </div>)}
                        {questionAttempts > 0 && hasSelectedCorrect === true && answerButtonReset === false && (<div style={{ display: 'flex', flex: 1 }}>
                            <input type="submit" value="Correct" style={{ 
                                textDecoration: 'none', 
                                width: styles(windowDimensions.width).mobileButton,
                                borderBottom: 0,  
                                textTransform: 'uppercase',
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginBottom: '1.0rem',
                                lineHeight: '1.5rem',
                                border: 0,
                                borderRadius: '0.3rem',
                                cursor: 'pointer',
                                display: 'block',
                                fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
                                outline: 'none',
                                padding: '1.875rem',
                                textAlign: 'center',
                                backgroundColor: '#11dd44',
                                boxShadow:'0 0.2rem #118833',
                                color: '#FFFFFF',
                                textShadow: '0 1px #113311',
                                position: 'relative'
                            }} />
                        </div>)}
                        {((answerButton >= 0 && questionAttempts === 0 ) || answerButtonReset === true) && (<div style={{ display: 'flex', flex: 1 }}>
                            <input onClick={async () => {
                                if (answerButton >= 0) {
                                    const qAttempt = await isCorrect(answerBooleans[answerButton])
                                    setAnswerButtonReset(false)
                                    setHasSelectedCorrect(qAttempt)
                                    setQuestionAttempts(questionAttempts+1)
                                }
                            }} type="submit" value="Check my answer" style={{ 
                                textDecoration: 'none', 
                                width: styles(windowDimensions.width).mobileButton,
                                borderBottom: 0,  
                                textTransform: 'uppercase',
                                fontVariant: 'small-caps',
                                letterSpacing: '0.08rem',
                                fontSize: '1.1rem',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginBottom: '1.0rem',
                                lineHeight: '1.5rem',
                                border: 0,
                                borderRadius: '0.3rem',
                                cursor: 'pointer',
                                display: 'block',
                                fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
                                outline: 'none',
                                padding: '1.875rem',
                                textAlign: 'center',
                                backgroundColor: '#00c',
                                boxShadow:'0 0.2rem #008',
                                color: '#FFFFFF',
                                textShadow: '0 1px #00a',
                                position: 'relative'
                            }} />
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
        <div style={{ 
            marginBottom: '4.5rem', 
            paddingBottom: '12rem', 
            display: 'flex', 
            flexDirection: 'row', 
            width: '100%',
            alignItems: 'center', 
            justifyContent: 'center',
            borderTopWidth: '0.225rem',
            borderTopStyle: 'solid',
            borderTopColor: 'rgb(236, 240, 241)'
        }}> 
            <div style={{ marginTop: '1.5rem' }}>
                <Link to="/terms_of_use" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5 }}>Terms of use</Link> · 
                <Link to="/privacy_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Privacy Policy</Link> · 
                <Link to="/cookies_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Cookies Policy</Link> · 
                <Link to="/system_requirements" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>System Requirements</Link> · 
                {/*<Link to="/faq" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>FAQ</Link> · */}
                <Link to="/credits" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingLeft: 5 }}>Credits</Link>
            </div>
        </div>
    </div>
    {showSummary > -1 && 
        (<div style={{ position: 'fixed', backgroundColor: 'rgba(0,0,0,0.7)', top: 0, bottom: 0, left: 0, right: 0, overflow: 'hidden', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ backgroundColor: '#FFFFFF', maxWidth: 700, width: windowDimensions.width-32, height: 640, borderRadius: 5 }}>
                <div style={{position: 'relative', float: 'right', width: styles(windowDimensions.width).mobile ? 24 : 60, height: styles(windowDimensions.width).mobile ? 24 : 60, display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', borderRadius: styles(windowDimensions.width).mobile ? 12 : 30, backgroundColor: '#FFF', top: styles(windowDimensions.width).mobile ? -12 : -30, right: styles(windowDimensions.width).mobile ? -12 : -30, borderWidth: 2, borderColor: '#000', border: 'solid'}} onClick={() => {
                    setShowSummary(-1)
                    setReturnedSummaryUrl("")
                    //window.history.pushState({}, "", "/q/orchestra_tuning");
                }}>
                    <div style={{width: styles(windowDimensions.width).mobile ? 12 : 30, height: styles(windowDimensions.width).mobile ? 12 : 30, position: 'relative', display: 'flex', justifyContent: 'center', backgroundColor: '#FFFFFF'}}>
                        <div style={{width: '1.5px', height: '100%', backgroundColor: '#000', position: 'absolute', transform: 'rotate(45deg)', borderRadius: '2px'}}></div>
                        <div style={{width: '1.5px', height: '100%', backgroundColor: '#000', position: 'absolute', transform: 'rotate(-45deg)', borderRadius: '2px'}}></div>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <img style={{ maxWidth: 640, width: windowDimensions.width-32, maxHeight: 480, height: ((windowDimensions.width-32)/640)*480, margin: 'auto' }} src={returnedSummaryUrl} />
                </div>
            </div>
        </div>)}
    {showVideo > -1 && (!styles(windowDimensions.width).mobile ? 
        (<div style={{ position: 'fixed', backgroundColor: 'rgba(0,0,0,0.7)', top: 0, bottom: 0, left: 0, right: 0, overflow: 'hidden', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ backgroundColor: '#FFFFFF', maxWidth: confusedOpen ? 1000 : 700, width: windowDimensions.width-32, height: 640, borderRadius: 5 }}>
                <div style={{position: 'relative', float: 'right', width: styles(windowDimensions.width).mobile ? 24 : 60, height: styles(windowDimensions.width).mobile ? 24 : 60, display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', borderRadius: styles(windowDimensions.width).mobile ? 12 : 30, backgroundColor: '#FFF', top: styles(windowDimensions.width).mobile ? -12 : -30, right: styles(windowDimensions.width).mobile ? -12 : -30, borderWidth: 2, borderColor: '#000', border: 'solid'}} onClick={async () => {
                    await setShowVideo(-1)
                    await setReturnedVideoUrl("")
                    await setSelectedVideo(-1)
                    await setVideoQuestionAttempts(0)
                    await setPlaying(true)
                    await setConfusedOpen(false)
                    await setConfusedObject(null)
                }}>
                    <div style={{width: styles(windowDimensions.width).mobile ? 12 : 30, height: styles(windowDimensions.width).mobile ? 12 : 30, position: 'relative', display: 'flex', justifyContent: 'center', backgroundColor: '#FFFFFF'}}>
                        <div style={{width: '1.5px', height: '100%', backgroundColor: '#000', position: 'absolute', transform: 'rotate(45deg)', borderRadius: '2px'}}></div>
                        <div style={{width: '1.5px', height: '100%', backgroundColor: '#000', position: 'absolute', transform: 'rotate(-45deg)', borderRadius: '2px'}}></div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: "row" }}>
                    <div style={{ display: 'flex', maxWidth: '100%', justifyContent: confusedOpen ? 'left' : 'center', flexDirection: "column", marginLeft: confusedOpen ? 8 : 30 }} onMouseOver={() => {
                        if (playing) {
                            setControlsShow(true)
                        }
                        }} onMouseOut={() => {
                            setControlsShow(false)
                        }}>
                        <ReactPlayer progressInterval={300} ref={ref} onProgress={(data) => {
                            let times = []
                            let time = null
                            let ind = null
                            let vQuestions = localQuestionData[questionId].videoData[showSummaryData[showVideo].title].questions
                            //console.log("vQuestions", vQuestions)
                            if (Object.keys(vQuestions).length > 1) {
                                //console.log("vQuestions", vQuestions)
                                if (vQuestions?.boat) {
                                    times = [vQuestions.boat.time, vQuestions.house.time]
                                } else if (vQuestions?.address) {
                                    times = [vQuestions.house.time, vQuestions.address.time]
                                } else if (vQuestions?.va) {
                                    times = [vQuestions.va.time, vQuestions.vb.time]
                                } else if (vQuestions?.series) {
                                    times = [vQuestions.series.time, vQuestions.parallel.time]
                                }
                                
                            } else {
                                if (vQuestions?.house?.time) {
                                    times = [vQuestions.house.time]
                                } else {
                                    times = []
                                }
                            }
                            
                            times.forEach((t, index) => {
                                if (data.playedSeconds < t + 3 && ind === null) {
                                    ind = index
                                    time = times[index]
                                    setVQuestionIndex(index)
                                }
                                //console.log(ind, t, time, data.playedSeconds, vQuestionIndex)
                                if (data.playedSeconds >= times[vQuestionIndex] + 3) {
                                    //console.log("CHIP", time, data.playedSeconds, t)
                                    ind = null
                                    setIsVideoCorrect(false)
                                    setIsVideoIncorrect(false)
                                    setSelectedVideo(-1)
                                    setVideoQuestionAttempts(0)
                                }
                            })
                            
                            
                            if (time && data.playedSeconds > time-0.15 && data.playedSeconds < time+0.15) {
                                // Video here !!
                                setPlaying(false)
                            }
                        }} playing={playing} controls={controlsShow} width={640} height={480} url={returnedVideoUrl} />
                        {!playing && <div style={{ position: 'absolute', width: '640px', height: '480px', backgroundColor: '#00000000' }}>
                            {getVideoQuestion(localQuestionData[questionId].videoData[showSummaryData[showVideo].title].questions, vQuestionIndex).answer.map((answer, index) => {
                                
                                return (<div style={{ position: 'absolute', paddingLeft: 6, paddingRight: 6, left: !confusedOpen ? answer.left+4 : answer.left, top: !confusedOpen ? answer.top-28 : answer.top, border: "3px solid #00f", marginHorizontal: 5, borderRadius: 5, backgroundColor: 'white' }}>
                                <input type="radio" name="vq" 
                                    checked={selectedVideo === index ? true : false} 
                                    onChange={() => {
                                        setSelectedVideo(index)
                                    }} />
                                    </div>)
                            })}
                            {
                                selectedVideo > -1 && (
                                    <div style={{ top: getVideoQuestion(localQuestionData[questionId].videoData[showSummaryData[showVideo].title].questions, vQuestionIndex).buttonCheckTop, left: getVideoQuestion(localQuestionData[questionId].videoData[showSummaryData[showVideo].title].questions, vQuestionIndex).buttonCheckLeft === 520 ? 490 : getVideoQuestion(localQuestionData[questionId].videoData[showSummaryData[showVideo].title].questions, vQuestionIndex).buttonCheckLeft, justifyContent: 'center', alignItems: 'center', backgroundColor: 'blue', paddingLeft: 12, paddingRight: 12, color: 'white', position: 'absolute', borderRadius: 6 }}  onClick={() => {
                                        setVideoQuestionAttempts(videoQuestionAttempts+1)
                                        if (getVideoQuestion(localQuestionData[questionId].videoData[showSummaryData[showVideo].title].questions, vQuestionIndex).answer[selectedVideo].correct === true) {
                                            setIsVideoCorrect(true)
                                            setIsVideoIncorrect(false)
                                            ref.current.seekTo(ref.current.getCurrentTime()+0.3)
                                            setTimeout(() => {
                                                setPlaying(true)
                                            }, 2000)
                                        } else {
                                            setIsVideoCorrect(false)
                                            setIsVideoIncorrect(true)
                                            setTimeout(() => {
                                                setIsVideoIncorrect(false)
                                            }, 2000)
                                        }
                                    }}><p style={{ margin: 'auto', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", fontWeight: 'bold' }}>{videoQuestionAttempts > 0 ? 'Check again' : 'Check my answer'}</p></div>
                                ) 
                            }
                            {
                                selectedVideo > -1 && videoQuestionAttempts > 0 && (
                                    <div style={{ top: getVideoQuestion(localQuestionData[questionId].videoData[showSummaryData[showVideo].title].questions, vQuestionIndex).buttonContinueTop, left: getVideoQuestion(localQuestionData[questionId].videoData[showSummaryData[showVideo].title].questions, vQuestionIndex).buttonContinueLeft === 520 ? 490 : getVideoQuestion(localQuestionData[questionId].videoData[showSummaryData[showVideo].title].questions, vQuestionIndex).buttonContinueLeft, justifyContent: 'center', alignItems: 'center', backgroundColor: 'blue', paddingLeft: 12, paddingRight: 12, color: 'white', position: 'absolute', borderRadius: 6 }}  onClick={() => {
                                        ref.current.seekTo(ref.current.getCurrentTime()+0.3)
                                        setPlaying(true)
                                    }}><p style={{ margin: 'auto', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", fontWeight: 'bold' }}>{'Skip question'}</p></div>
                                ) 
                            }
                            {
                                selectedVideo > -1 && videoQuestionAttempts > 0 && (
                                    <div style={{ top: getVideoQuestion(localQuestionData[questionId].videoData[showSummaryData[showVideo].title].questions, vQuestionIndex).toastCorrectTop, left: getVideoQuestion(localQuestionData[questionId].videoData[showSummaryData[showVideo].title].questions, vQuestionIndex).toastCorrectLeft === 520 ? 490 : getVideoQuestion(localQuestionData[questionId].videoData[showSummaryData[showVideo].title].questions, vQuestionIndex).toastCorrectLeft, justifyContent: 'center', alignItems: 'center', backgroundColor: isVideoCorrect ? 'green' : isVideoIncorrect ? 'red' : 'blue', paddingLeft: 2, paddingRight: 2, color: 'white', position: 'absolute', borderRadius: 6 }}  onClick={() => {
                                        ref.current.seekTo(ref.current.getCurrentTime()+0.3)
                                        setPlaying(true)    
                                    }}><p style={{ margin: 'auto', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", fontWeight: 'bold' }}>{isVideoCorrect ? 'Correct' : isVideoIncorrect && 'Wrong! Try again?'}</p></div>
                                ) 
                            }
                        </div>}
                        <div style={{ width: 640, margin: confusedOpen ? 0 : 'auto', marginTop: 8, flexDirection: 'row', display: 'flex' }}>
                            <div style={{ display: 'flex', flex: 2, marginTop: 8 }}>
                                {localQuestionData[questionId].videoData[showSummaryData[showVideo].title]?.indexes?.question && localQuestionData[questionId].videoData[showSummaryData[showVideo].title].indexes.question.map((questionButton, index) => {
                                        return <div style={{ height: 40, width: 120, justifyContent: 'center', alignItems: 'center', backgroundColor: 'blue', paddingHorizontal: 12, color: 'white', display: 'flex', borderRadius: 6 }}  onClick={() => {
                                            setIsVideoIncorrect(false)
                                            setIsVideoCorrect(false)
                                            setSelectedVideo(-1)
                                            ref.current.seekTo(questionButton)
                                            setPlaying(true)
                                        }}><p style={{ margin: 'auto', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", fontWeight: 'bold' }}>{`Question ${index+1}`}</p></div>
                                })}
                            </div>
                            {!confusedOpen && confusedIds.includes(showSummaryData[showVideo].title) && <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', marginTop: 8 }}> 
                                {auth().currentUser && <div style={{ height: 40, width: 120, justifyContent: 'center', alignItems: 'center', backgroundColor: 'red', paddingHorizontal: 12, color: 'white', display: 'flex', borderRadius: 6 }}  onClick={async () => {
                                        if (!confusedOpen) {
                                            const videoId = showSummaryData[showVideo].title
                                            const snapshot = await firestore.collection(`Confused`).doc(videoId).get();
                                            //console.log("snapshot", snapshot)
                                            const questionData = snapshot.data();
                                            //console.log("questionData", questionData)
                                            setConfusedOpen(true)
                                            setConfusedObject(questionData)
                                            //alert(questionButton)
                                        } else {
                                            setConfusedOpen(false)
                                            setConfusedObject(null)
                                        }
                                    }}><p style={{ margin: 'auto', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", fontWeight: 'bold' }}>{`Confused`}</p></div>}
                            </div>}
                        </div>
                    </div>
                    {confusedOpen && <div style={{ marginLeft: 8, marginTop: 8, borderLeft: "2px solid #CCCCCC" }}>
                        <p style={{ fontSize: 22, fontWeight: 'bold', marginLeft: 8 }}>FAQs</p>
                        <div style={{ overflowY: 'scroll', height: 580, marginLeft: 8, width: 320 }}>
                            {confusedOpen && confusedObject && confusedObject.queries && confusedObject.queries.map((qt) => {
                                
                                if (qt?.answer) {
                                    let splitArray = [qt.answer] 
                                    if (qt.answer.includes("<br />")) {
                                        splitArray = qt.answer.split("<br />")
                                    }
                                    return (<div>
                                        <p style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 2 }}>User Query:</p>
                                        <p style={{ fontSize: 16, marginTop: 2 }}>{qt.query}</p>
                                        <p style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 2 }}>Answer:</p>
                                        {splitArray.map((a) => {
                                            return (<p>{a}</p>)
                                        })}
                                    </div>)
                                } else if (qt?.answerArray) {
                                    return (<div>
                                        <p style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 2 }}>User Query:</p>
                                        <p style={{ fontSize: 16, marginTop: 2 }}>{qt.query}</p>
                                        <p style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 2 }}>Answer:</p>
                                        {qt.answerArray.map((a) => {
                                            if (a.type == "text") {
                                                let splitArray = [a.text] 
                                                if (a.text.includes("<br />")) {
                                                    splitArray = a.text.split("<br />")
                                                }
                                                return (<div>
                                                {splitArray.map((aa) => {
                                                    return <p>{aa}</p>
                                                })}
                                                </div>)
                                                //return <p>{a.text}</p>
                                            } else if (a.type == "image") {
                                                return <img src={a.image} style={{ width: 200}} />
                                            }
                                        })}
                                    </div>)
                                }
                            })}
                            <div style={{ width: 10, height: 50 }} />
                        </div>
                        {confusedOpen && <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', marginTop: 8, position: "absolute", marginTop: -48 }}> 
                                {auth().currentUser && <div style={{ height: 40, width: 300, marginLeft: 10, justifyContent: 'center', alignItems: 'center', backgroundColor: 'red', paddingHorizontal: 12, color: 'white', display: 'flex', borderRadius: 6 }}  onClick={async () => {
                                        if (!confusedOpen) {
                                            const videoId = showSummaryData[showVideo].title
                                            const snapshot = await firestore.collection(`Confused`).doc(videoId).get();
                                            //console.log("snapshot", snapshot)
                                            const questionData = snapshot.data();
                                            //console.log("questionData", questionData)
                                            setConfusedOpen(true)
                                            setConfusedObject(questionData)
                                            //alert(questionButton)
                                        } else {
                                            setConfusedOpen(false)
                                            setConfusedObject(null)
                                        }
                                    }}><p style={{ margin: 'auto', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", fontWeight: 'bold' }}>{`Close`}</p></div>}
                            </div>}
                    </div>}
                </div>
                
            </div>
        </div>) :
        (<div style={{ position: "fixed", flex: 1, backgroundColor: "white", width: windowDimensions.width, height: "110vh", top: 0, bottom: 0 }}>
            <div style={{ display: "flex", position: "relative", top: 0, left: 0, marginBottom: 20, marginTop: 8, justifyContent: "flex-end" }} onClick={async () => {
                await setShowVideo(-1)
                await setReturnedVideoUrl("")
                await setSelectedVideo(-1)
                await setVideoQuestionAttempts(0)
                await setPlaying(true)
                await setConfusedOpen(false)
                await setConfusedObject(null)
            }}><p style={{ fontSize: 22, marginRight: 8 }}>Close</p></div>
            {confusedOpen && <div style={{ position: "absolute", height: "100%", width: "100%" }}>
            <div style={{ marginLeft: 8, marginTop: 8 }}>
                        <p style={{ fontSize: 22, fontWeight: 'bold', marginLeft: 8 }}>FAQs</p>
                        <div style={{ overflowY: 'scroll', height: "100vh", marginLeft: 8, paddingBottom: 300 }}>
                            {confusedOpen && confusedObject && confusedObject.queries && confusedObject.queries.map((qt) => {
                                
                                if (qt?.answer) {
                                    let splitArray = [qt.answer] 
                                    if (qt.answer.includes("<br />")) {
                                        splitArray = qt.answer.split("<br />")
                                    }
                                    return (<div>
                                        <p style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 2 }}>User Query:</p>
                                        <p style={{ fontSize: 16, marginTop: 2 }}>{qt.query}</p>
                                        <p style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 2 }}>Answer:</p>
                                        {splitArray.map((a) => {
                                            return (<p>{a}</p>)
                                        })}
                                    </div>)
                                } else if (qt?.answerArray) {
                                    return (<div>
                                        <p style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 2 }}>User Query:</p>
                                        <p style={{ fontSize: 16, marginTop: 2 }}>{qt.query}</p>
                                        <p style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 2 }}>Answer:</p>
                                        {qt.answerArray.map((a) => {
                                            if (a.type == "text") {
                                                let splitArray = [a.text] 
                                                if (a.text.includes("<br />")) {
                                                    splitArray = a.text.split("<br />")
                                                }
                                                return (<div>
                                                {splitArray.map((aa) => {
                                                    return <p>{aa}</p>
                                                })}
                                                </div>)
                                                //return <p>{a.text}</p>
                                            } else if (a.type == "image") {
                                                return <img src={a.image} style={{ width: 200}} />
                                            }
                                        })}
                                    </div>)
                                }
                            })}
                            <div style={{ width: 10, height: 100 }} />
                        </div>
                </div>
            </div>}
            {!confusedOpen && <div style={{ display: 'flex', maxWidth: '100%', flexDirection: "column", marginTop: 20 }} onMouseOver={() => {
                        if (playing) {
                            setControlsShow(true)
                        }
                        }} onMouseOut={() => {
                            setControlsShow(false)
                        }}>
                        <ReactPlayer progressInterval={300} ref={ref} onProgress={(data) => {
                            let times = []
                            let time = null
                            let ind = null
                            let vQuestions = localQuestionData[questionId].videoData[showSummaryData[showVideo].title].questions

                            if (Object.keys(vQuestions).length > 1) {
                                //console.log("vQuestions", vQuestions)
                                if (vQuestions?.boat) {
                                    times = [vQuestions.boat.time, vQuestions.house.time]
                                } else if (vQuestions?.address) {
                                    times = [vQuestions.house.time, vQuestions.address.time]
                                } else if (vQuestions?.va) {
                                    times = [vQuestions.va.time, vQuestions.vb.time]
                                } else if (vQuestions?.series) {
                                    times = [vQuestions.series.time, vQuestions.parallel.time]
                                }
                                
                            } else {
                                if (vQuestions?.house?.time) {
                                    times = [vQuestions.house.time]
                                } else {
                                    times = []
                                }
                            }
                            
                            times.forEach((t, index) => {
                                if (data.playedSeconds < t + 3 && ind === null) {
                                    ind = index
                                    time = times[index]
                                    setVQuestionIndex(index)
                                }
                                //console.log(ind, t, time, data.playedSeconds, vQuestionIndex)
                                if (data.playedSeconds >= times[vQuestionIndex] + 3) {
                                    //console.log("CHIP", time, data.playedSeconds, t)
                                    ind = null
                                    setIsVideoCorrect(false)
                                    setIsVideoIncorrect(false)
                                    setSelectedVideo(-1)
                                    setVideoQuestionAttempts(0)
                                }
                            })
                            
                            
                            if (time && data.playedSeconds > time-0.15 && data.playedSeconds < time+0.15) {
                                // Video here !!
                                setPlaying(false)
                            }
                        }} playing={playing} controls={controlsShow} width={windowDimensions.width} height={480*(windowDimensions.width/640)} url={returnedVideoUrl} />
                        {!playing && <div style={{ position: 'absolute', width: windowDimensions.width+'px', height: 480*(windowDimensions.width/640) + 'px', backgroundColor: '#00000000', marginBottom: 8 }}>
                            {getVideoQuestion(localQuestionData[questionId].videoData[showSummaryData[showVideo].title].questions, vQuestionIndex).answer.map((answer, index) => {
                                
                                return (<div style={{ position: 'absolute', paddingLeft: 4, paddingRight: 4, left: answer.left*(windowDimensions.width/640)-5, top: answer.top*(windowDimensions.width/640)-5, border: "2px solid #00f", marginHorizontal: 3, borderRadius: 5, backgroundColor: 'white' }}>
                                <input type="radio" name="vq" 
                                    checked={selectedVideo === index ? true : false} 
                                    onChange={() => {
                                        setSelectedVideo(index)
                                    }} />
                                    </div>)
                            })}
                        </div>}
                        {<div style={{ position: "relative", width: 200 }}>
                            {
                                !playing && selectedVideo > -1 && (
                                    <div style={{ position: "relative", backgroundColor: 'blue', paddingLeft: 12, paddingRight: 12, color: 'white', borderRadius: 6, marginLeft: 8, marginBottom: 8 }}  onClick={() => {
                                        setVideoQuestionAttempts(videoQuestionAttempts+1)
                                        if (getVideoQuestion(localQuestionData[questionId].videoData[showSummaryData[showVideo].title].questions, vQuestionIndex).answer[selectedVideo].correct === true) {
                                            setIsVideoCorrect(true)
                                            setIsVideoIncorrect(false)
                                            ref.current.seekTo(ref.current.getCurrentTime()+0.3)
                                            setTimeout(() => {
                                                setPlaying(true)
                                            }, 2000)
                                        } else {
                                            setIsVideoCorrect(false)
                                            setIsVideoIncorrect(true)
                                            setTimeout(() => {
                                                setIsVideoIncorrect(false)
                                            }, 2000)
                                        }
                                    }}><p style={{ margin: 'auto', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", fontWeight: 'bold' }}>{videoQuestionAttempts > 0 ? 'Check again' : 'Check my answer'}</p></div>
                                ) 
                            }
                            {
                                !playing && selectedVideo > -1 && videoQuestionAttempts > 0 && (
                                    <div style={{ position: "relative", justifyContent: 'center', alignItems: 'center', backgroundColor: 'blue', paddingLeft: 12, paddingRight: 12, color: 'white', borderRadius: 6, marginLeft: 8, marginBottom: 8 }}  onClick={() => {
                                            ref.current.seekTo(ref.current.getCurrentTime()+0.3)
                                            setPlaying(true)
                                    }}><p style={{ margin: 'auto', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", fontWeight: 'bold' }}>{'Skip question'}</p></div>
                                ) 
                            }
                            {
                                !playing && selectedVideo > -1 && videoQuestionAttempts > 0 && (
                                    <div style={{position: "relative", justifyContent: 'center', alignItems: 'center', backgroundColor: isVideoCorrect ? 'green' : isVideoIncorrect ? 'red' : 'blue', paddingLeft: 2, paddingRight: 2, color: 'white', borderRadius: 6, marginLeft: 8, marginBottom: 8 }}  onClick={() => {
                                            ref.current.seekTo(ref.current.getCurrentTime()+0.3)
                                            setPlaying(true)    
                                    }}><p style={{ margin: 'auto', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", fontWeight: 'bold' }}>{isVideoCorrect ? 'Correct' : isVideoIncorrect && 'Wrong! Try again?'}</p></div>
                                ) 
                            }
                        </div>}
                        <div style={{ width: windowDimensions.width, margin: confusedOpen ? 0 : 'auto', marginTop: 24, flexDirection: 'row', display: 'flex' }}>
                            <div style={{ display: 'flex', flex: 2, marginTop: 8 }}>
                                {localQuestionData[questionId].videoData[showSummaryData[showVideo].title]?.indexes?.question && localQuestionData[questionId].videoData[showSummaryData[showVideo].title].indexes.question.map((questionButton, index) => {
                                        return <div style={{ height: 40, width: 120, justifyContent: 'center', alignItems: 'center', backgroundColor: 'blue', paddingHorizontal: 12, color: 'white', display: 'flex', borderRadius: 6 }}  onClick={() => {
                                            //alert(questionButton)
                                            setIsVideoIncorrect(false)
                                            setIsVideoCorrect(false)
                                            setSelectedVideo(-1)
                                            ref.current.seekTo(questionButton)
                                            setPlaying(true)
                                        }}><p style={{ margin: 'auto', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", fontWeight: 'bold' }}>{`Question ${index+1}`}</p></div>
                                })}
                            </div>
                        </div>
                    </div>}
            {!confusedOpen && <div style={{ position: "fixed", left: 0, bottom: 0, display: 'flex', flex: 1, marginTop: 8, width: "100%" }}> 
                {auth().currentUser && <div style={{ height: 40, width: "100%", justifyContent: 'center', alignItems: 'center', backgroundColor: 'red', paddingHorizontal: 12, color: 'white', display: 'flex', borderRadius: 6 }}  onClick={async () => {
                        if (!confusedOpen) {
                            const videoId = showSummaryData[showVideo].title
                            const snapshot = await firestore.collection(`Confused`).doc(videoId).get();
                            //console.log("snapshot", snapshot)
                            const questionData = snapshot.data();
                            //console.log("questionData", questionData)
                            setConfusedOpen(true)
                            setConfusedObject(questionData)
                            //alert(questionButton)
                        } else {
                            setConfusedOpen(false)
                            setConfusedObject(null)
                        }
                    }}><p style={{ margin: 'auto', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", fontWeight: 'bold' }}>{`Confused`}</p></div>}
            </div>}
            {confusedOpen && <div style={{ width: "100%", bottom: 0, left: 0, position: "fixed", display: 'flex', flex: 1, justifyContent: 'flex-end', marginTop: 8 }}> 
                {auth().currentUser && <div style={{ height: 40, width: "100%", justifyContent: 'center', alignItems: 'center', backgroundColor: 'red', paddingHorizontal: 12, color: 'white', display: 'flex', borderRadius: 6 }}  onClick={async () => {
                        if (!confusedOpen) {
                            const videoId = showSummaryData[showVideo].title
                            const snapshot = await firestore.collection(`Confused`).doc(videoId).get();
                            //console.log("snapshot", snapshot)
                            const questionData = snapshot.data();
                            //console.log("questionData", questionData)
                            setConfusedOpen(true)
                            setConfusedObject(questionData)
                            //alert(questionButton)
                        } else {
                            setConfusedOpen(false)
                            setConfusedObject(null)
                        }
                    }}><p style={{ margin: 'auto', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", fontWeight: 'bold' }}>{`Close`}</p></div>}
            </div>}
        </div>))}
    {showCorrectVideo && false && 
        (<div style={{ position: 'fixed', backgroundColor: 'rgba(0,0,0,0.7)', top: 0, bottom: 0, left: 0, right: 0, overflow: 'hidden', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ backgroundColor: '#FFFFFF', maxWidth: 700, width: windowDimensions.width-32, height: 640, borderRadius: 5 }}>
                <div style={{position: 'relative', float: 'right', width: styles(windowDimensions.width).mobile ? 24 : 60, height: styles(windowDimensions.width).mobile ? 24 : 60, display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', borderRadius: styles(windowDimensions.width).mobile ? 6 : 30, backgroundColor: '#FFF', top: styles(windowDimensions.width).mobile ? -12 : -30, right: styles(windowDimensions.width).mobile ? -12 : -30, borderWidth: 2, borderColor: '#000', border: 'solid'}} onClick={() => {
                    setShowCorrectVideo(false)
                }}>
                    <div style={{width: styles(windowDimensions.width).mobile ? 12 : 30, height: styles(windowDimensions.width).mobile ? 12 : 30, position: 'relative', display: 'flex', justifyContent: 'center', backgroundColor: '#FFFFFF'}}>
                        <div style={{width: '1.5px', height: '100%', backgroundColor: '#000', position: 'absolute', transform: 'rotate(45deg)', borderRadius: '2px'}}></div>
                        <div style={{width: '1.5px', height: '100%', backgroundColor: '#000', position: 'absolute', transform: 'rotate(-45deg)', borderRadius: '2px'}}></div>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <ReactPlayer playing={true} controls width={640} height={480} url={`https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/video%2Fcongrat_w3_s.mp4?alt=media&token=e5385138-75f0-459f-b508-4d96aff7a3a0`} />
                </div>
            </div>
        </div>)}
    </div>
  );
}

export default Question;